import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { 
  Button, 
  FormControl, 
  FormLabel, 
  Input, 
  VStack, 
  useToast, 
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Box
} from '@chakra-ui/react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import InvoicePreview from '../invoice-creation/InvoicePreview';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const generatePDF = async (data, logoUrl, companyName) => {
  console.log('Generating PDF with logoUrl:', logoUrl);
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  let yPos = 20;

  // Add logo only if provided
  // Function to convert the image URL to a base64 data URL
  const loadImageAsBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error loading image:', error);
      return null;
    }
  };

  if (logoUrl) {
    try {
      const logoDataUrl = await loadImageAsBase64(logoUrl);
      if (logoDataUrl) {
        const imgWidth = 16; // Adjust the size of the logo as needed
        const imgHeight = 16; // Maintain the aspect ratio of the logo
        const logoX = pageWidth - imgWidth - 25; // Position 25 units from the right edge
        const logoY = 25; // Position 25 units from the top edge
        doc.addImage(logoDataUrl, 'PNG', logoX, logoY, imgWidth, imgHeight);
        console.log('Logo added to PDF successfully');

        // Measure the text width
        const textWidth = doc.getTextWidth(companyName);

        // Calculate the center of the logo
        const textX = logoX + (imgWidth / 2) - (textWidth / 2);

        // Draw the company name centered beneath the logo
        doc.setFontSize(16);
        doc.text(companyName, textX, logoY + imgHeight + 6); // Adjust '6' for spacing between logo and text
      } else {
        console.log('Failed to convert logo to base64, skipping logo in PDF');

        // Center the company name on the page since the logo is not included
        const textWidth = doc.getTextWidth(companyName);
        const textX = (pageWidth - textWidth-25);
        doc.setFontSize(20);
        doc.text(companyName, textX, 28); // Position at a suitable Y-coordinate
      }
    } catch (error) {
      console.error('Error adding logo to PDF:', error);

      // Center the company name on the page since the logo is not included
      const textWidth = doc.getTextWidth(companyName);
      const textX = (pageWidth - textWidth - 25);
      doc.setFontSize(20);
      doc.text(companyName, textX, 28); // Position at a suitable Y-coordinate
    }
  } else {
    console.log('No logo URL provided, centering company name');

    // Center the company name on the page since the logo is not included
    const textWidth = doc.getTextWidth(companyName);
    const textX = (pageWidth - textWidth - 30);
    doc.setFontSize(20);
    doc.text(companyName, textX, 28); // Position at a suitable Y-coordinate
  }

  // Adjust x positions for text to align it next to the logo
  const textX = 25; // Adjust this value to control the spacing between the logo and the text
  doc.setFontSize(11);
  doc.text(`Invoice Number: ${data.invoiceNumber || 'N/A'}`, textX, yPos + 8);
  doc.text(`Status: ${data.paymentStatus || 'N/A'}`, textX, yPos + 14);
  doc.text(`Issue Date: ${formatDate(data.invoiceDate)}`, textX, yPos + 20);
  doc.text(`Due Date: ${formatDate(data.dueDate)}`, textX, yPos + 26);

  // Adjust yPos for the next section
  yPos += 36; // Increase yPos only after adding the logo and text in the same row

  // From and To sections
  doc.setFontSize(11);
  doc.text('From:', 25, yPos);
  doc.setFontSize(11);
  doc.text(data.from.name || 'Sender Name', 25, yPos + 5);
  doc.text(data.from.address || 'Sender Address', 25, yPos + 10);
  if (data.from.email) doc.text(data.from.email, 25, yPos + 15);
  if (data.from.phone) doc.text(data.from.phone, 25, yPos + 20);

  doc.setFontSize(11);
  doc.text('To:', pageWidth / 2 + 5, yPos);
  doc.setFontSize(11);
  doc.text(data.customerName, pageWidth / 2 + 5, yPos + 5);
  doc.text(data.customerAddress || 'Customer Address', pageWidth / 2 + 5, yPos + 10);
  if (data.customerEmail) doc.text(data.customerEmail, pageWidth / 2 + 5, yPos + 15);
  if (data.customerPhone) doc.text(data.customerPhone, pageWidth / 2 + 5, yPos + 20);

  yPos += 30;

  // Items table
  doc.autoTable({
    startY: yPos,
    margin: { left: 25, right: 25 },
    head: [['Item', 'Quantity', 'Price', 'Total']],
    body: data.items.map(item => [
      item.itemName,
      item.quantity,
      `${formatCurrency(item.price)} ${data.currency}`,
      `${formatCurrency(Number(item.quantity) * Number(item.price))} ${data.currency}`
    ]),
    styles: {
      fontSize: 11,
      lineWidth: 0.1,
      lineColor: [100, 100, 100]
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
      fontStyle: 'bold',
      fontSize: 11
    },
    alternateRowStyles: { fillColor: false },
  });
  
  yPos = doc.lastAutoTable.finalY + 10;

  // Payment details and totals
  doc.setFontSize(11);
  doc.text(`Currency: ${data.currency || 'N/A'}`, 25, yPos + 10);
  doc.text(`Payment Method: ${data.paymentMethod || 'N/A'}`, 25, yPos);

  doc.text('Subtotal:', pageWidth - 70, yPos);
  doc.text(`${formatCurrency(data.subtotal)} ${data.currency}`, pageWidth - 25, yPos, { align: 'right' });
  
  doc.text(`Tax (${data.taxRate || 0}%):`, pageWidth - 70, yPos + 5);
  doc.text(`${formatCurrency(data.taxAmount)} ${data.currency}`, pageWidth - 25, yPos + 5, { align: 'right' });
  
  doc.setFontSize(11);
  doc.setDrawColor(200, 200, 200);
  doc.line(pageWidth - 70, yPos + 10, pageWidth - 25, yPos + 10);
  doc.text('Total:', pageWidth - 70, yPos + 15);
  doc.text(`${formatCurrency(data.totalAmount)} ${data.currency}`, pageWidth - 25, yPos + 15, { align: 'right' });

  // Additional details
  if (data.additionalDetails) {
    yPos += 25;
    doc.setFontSize(11);
    doc.text('Additional Details:', 25, yPos);
    doc.setFontSize(11);
    const splitText = doc.splitTextToSize(data.additionalDetails, pageWidth - 50);
    doc.text(splitText, 25, yPos + 5);
  }

  return doc.output('datauristring');
};

const formatDate = (dateString) => {
  return dateString ? new Date(dateString).toLocaleDateString() : 'N/A';
};

const formatCurrency = (value) => {
  const num = Number(value);
  return isNaN(num) ? '0.00' : num.toFixed(2);
};

const SendInvoice = ({ isOpen, onClose, selectedInvoice }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState(null);
  const toast = useToast();
  const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure();
  const {onOpen: onCompletionOpen} = useDisclosure();

  useEffect(() => {
    if (selectedInvoice && selectedInvoice.customerEmail) {
      setEmail(selectedInvoice.customerEmail);
    }

    const fetchLogoUrl = async () => {
      if (selectedInvoice && selectedInvoice.logoUrl) {
        try {
          const storage = getStorage();
          const logoRef = ref(storage, selectedInvoice.logoUrl);
          const url = await getDownloadURL(logoRef);
          console.log('Firebase Storage download URL:', url);
          setLogoUrl(url);
        } catch (error) {
          console.error('Error fetching logo URL:', error);
          setLogoUrl(null);
        }
      } else {
        console.log('No logo URL provided in the invoice');
        setLogoUrl(null);
      }
    };

    fetchLogoUrl();
  }, [selectedInvoice]);

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!selectedInvoice) {
      toast({
        title: 'Error',
        description: 'No invoice selected to send',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const companyName = selectedInvoice.from.companyName || '';
      const pdfContent = await generatePDF(selectedInvoice, logoUrl, companyName);

      const response = await fetch('https://send-invoice-36005537969.us-central1.run.app/send_invoice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          invoice_id: selectedInvoice.id,
          invoice_content: pdfContent,
          sendAddress: email,
          senderEmail: selectedInvoice.from.email,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send invoice');
      }

      // Update invoice status to 'Sent'
      const invoiceRef = doc(db, 'invoices', selectedInvoice.id);
      await updateDoc(invoiceRef, {
        status: 'Sent',
        sentDate: new Date().toISOString()
      });

      toast({
        title: 'Invoice Sent',
        description: `Invoice has been sent to ${email}`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setEmail('');
      onCompletionOpen(); // Open the completion modal
      onClose(); // Close the send invoice modal
    } catch (error) {
      console.error("Error sending invoice: ", error);
      toast({
        title: 'Error',
        description: 'Failed to send invoice. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = () => {
    if (selectedInvoice) {
      onPreviewOpen();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Invoice</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            {loading ? (
              <Spinner size="lg" alignSelf="center" />
            ) : (
              <form onSubmit={sendEmail}>
                <FormControl isRequired mb={4}>
                  <FormLabel htmlFor="email">Client Email:</FormLabel>
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <Button onClick={handlePreview} colorScheme="teal" width="100%" mb={4}>
                  Preview Invoice
                </Button>
                <Button type="submit" colorScheme="blue" width="100%" isLoading={loading}>
                  Send Invoice
                </Button>
              </form>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>

      {/* Invoice Preview Modal */}
      <Modal isOpen={isPreviewOpen} onClose={onPreviewClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invoice Preview</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box maxHeight="80vh" overflowY="auto">
              <InvoicePreview invoiceData={selectedInvoice} isScrollable={true} />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onPreviewClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Modal>
  );
};

export default SendInvoice;
