import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';

const BlogHero = () => {
  return (
    <Box
      bg="blue.600"
      color="white"
      py={16}
      backgroundImage="url('https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80')"
      backgroundSize="cover"
      backgroundPosition="center"
      position="relative"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="rgba(0, 0, 0, 0.6)"
      />
      <Container maxW="container.xl" position="relative" zIndex={1}>
        <Heading as="h1" size="3xl" mb={4}>
          InvoGen Blogs
        </Heading>
        <Text fontSize="xl" maxW="2xl">
          Discover insights, tips, and updates about invoice management and our platform. Stay informed and optimize your business processes with InvoGen.
        </Text>
      </Container>
    </Box>
  );
};

export default BlogHero;
