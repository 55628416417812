import { db } from '../firebase';
import { collection, getDocs, updateDoc } from 'firebase/firestore';

const updateSendersSchema = async () => {
  try {
    const sendersRef = collection(db, 'senders');
    const snapshot = await getDocs(sendersRef);

    const updatePromises = snapshot.docs.map(async (doc) => {
      const senderData = doc.data();
      if (!senderData.companyName) {
        await updateDoc(doc.ref, {
          companyName: ''
        });
        console.log(`Updated sender ${doc.id} with empty company name`);
      }
    });

    await Promise.all(updatePromises);
    console.log('All senders updated successfully');
  } catch (error) {
    console.error('Error updating senders:', error);
  }
};

export default updateSendersSchema;
