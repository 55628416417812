import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import SenderSelection from '../utils/SenderSelection';
import LogoUpload from '../utils/LogoUpload';

const SenderDetailsStep = ({ invoiceData, setInvoiceData, handleInputChange, handleLogoSelect }) => {
  return (
    <Box>
      <SenderSelection
        invoiceData={invoiceData}
        setInvoiceData={setInvoiceData}
        handleInputChange={handleInputChange}
      />
      <Box mt={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>Logo Upload</Text>
        <Text mb={4}>Upload your company logo to personalize your invoice. The logo will appear at the top of your invoice.</Text>
        <LogoUpload onLogoSelect={handleLogoSelect} />
      </Box>
    </Box>
  );
};

export default SenderDetailsStep;