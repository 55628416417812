import React from 'react';
import { VStack, Box, Heading, Text, Input, Button, Tag, HStack, Link } from '@chakra-ui/react';

const BlogSidebar = () => {
  const popularPosts = [
    { id: 'Core-Features-InvoGen', title: "Optimize Invoicing Process with InvoGen" },
    { id: 'How-to-Write-Invoice-InvoGen', title: "How to Write an Invoice Using InvoGen: A Step-by-Step Guide" },
    { id: 'Understanding-Invoices', title: "What is an Invoice? Understanding the Essential Elements" },
    { id: 'Invoicing-Trends-Research', title: "10 Fascinating Insights from Invoicing and Billing Research" },
  ];

  const categories = ["Invoicing", "Small Business", "Finance", "Technology"];

  return (
    <VStack spacing={8} align="stretch">
      <Box>
        <Heading as="h3" size="md" mb={4}>
          Newsletter Signup
        </Heading>
        <VStack spacing={3}>
          <Input placeholder="Enter your email" />
          <Button colorScheme="blue" width="100%">
            Subscribe
          </Button>
        </VStack>
      </Box>

      <Box>
        <Heading as="h3" size="md" mb={4}>
          Popular Posts
        </Heading>
        <VStack align="stretch" spacing={2}>
          {popularPosts.map((post) => (
            <Link key={post.id} href={`/blogs/${post.id}`}>
              <Text fontWeight="medium" color="blue.600">
                {post.title}
              </Text>
            </Link>
          ))}
        </VStack>
      </Box>

      <Box>
        <Heading as="h3" size="md" mb={4}>
          Categories
        </Heading>
        <HStack wrap="wrap" spacing={2}>
          {categories.map((category) => (
            <Tag key={category} size="md" variant="outline" colorScheme="blue">
              {category}
            </Tag>
          ))}
        </HStack>
      </Box>
    </VStack>
  );
};

export default BlogSidebar;
