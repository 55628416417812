import React from 'react';
import { Box } from '@chakra-ui/react';
import InvoiceFinalReview from './InvoiceFinalReview';

const FinalReviewStep = ({ invoiceData, isInvoiceValid, onSaveDraft, onSendInvoice }) => {
  return (
    <Box>
      <InvoiceFinalReview
        invoiceData={invoiceData}
        isInvoiceValid={isInvoiceValid}
        onSaveDraft={onSaveDraft}
        onSendInvoice={onSendInvoice}
      />
    </Box>
  );
};

export default FinalReviewStep;