import React from 'react';
import { Box } from '@chakra-ui/react';
import ClientSelection from '../client/ClientSelection';

const ClientSelectionStep = ({ invoiceData, setInvoiceData, handleInputChange }) => {
  return (
    <Box>
      <ClientSelection
        invoiceData={invoiceData}
        setInvoiceData={setInvoiceData}
        handleInputChange={handleInputChange}
      />
    </Box>
  );
};

export default ClientSelectionStep;