import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
} from '@chakra-ui/react';

const ClientSelection = ({ invoiceData, handleInputChange }) => {
  return (
    <Box>
      <VStack spacing={4}>
        <Text>Enter client details for your invoice:</Text>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input name="customerName" value={invoiceData.customerName} onChange={handleInputChange} />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input name="customerEmail" value={invoiceData.customerEmail} onChange={handleInputChange} />
        </FormControl>
        <FormControl>
          <FormLabel>Address</FormLabel>
          <Input name="customerAddress" value={invoiceData.customerAddress} onChange={handleInputChange} />
        </FormControl>
        <FormControl>
          <FormLabel>Phone</FormLabel>
          <Input name="customerPhone" value={invoiceData.customerPhone} onChange={handleInputChange} />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default ClientSelection;
