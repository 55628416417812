import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Box, Flex, Button, Spacer, Text, Menu, MenuButton, MenuList, MenuItem, Avatar, useToast, Image, IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, VStack } from '@chakra-ui/react';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import logo from '../../assets/invogen.png';

const Navigation = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [profilePicture, setProfilePicture] = useState(null);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setProfilePicture(userData.profilePicture || null);
          }
        } catch (error) {
          console.error('Error fetching profile picture:', error);
        }
      }
    };

    fetchProfilePicture();
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
      toast({
        title: 'Logged out successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to log out', error);
      toast({
        title: 'Failed to log out',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isActive = (path) => location.pathname === path;

  const NavLink = ({ to, children }) => (
    <Link to={to}>
      <Button
        variant={isActive(to) ? "solid" : "ghost"}
        bg={isActive(to) ? "black" : "transparent"}
        color={isActive(to) ? "white" : "black"}
        size="sm"
        mx={3}
        _hover={isActive(to) ? {} : { bg: 'gray.200' }}
        transition="all 0.2s"
      >
        {children}
      </Button>
    </Link>
  );

  const CreateFreeInvoiceButton = () => (
    <Link to="/free-invoice-creation">
      <Button
        bg="blue.500"
        color="white"
        size="sm"
        mx={3}
        _hover={{ bg: 'blue.600' }}
        transition="all 0.2s"
        boxShadow="md"
      >
        Create Free Invoice
      </Button>
    </Link>
  );

  const CreateInvoiceButton = () => (
    <Link to="/create-invoice">
      <Button
        bg="blue.500"
        color="white"
        size="sm"
        mx={3}
        _hover={{ bg: 'blue.600' }}
        transition="all 0.2s"
        boxShadow="md"
      >
        Create Invoice
      </Button>
    </Link>
  );

  const handlePricingClick = () => {
    navigate('/');
    setTimeout(() => {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const NavContent = ({ isMobile = false }) => (
    <>
      {!currentUser && (
        <>
          <NavLink to="/blogs">Blogs</NavLink>
          <Button
            variant="ghost"
            bg="transparent"
            color="black"
            size="sm"
            mx={3}
            onClick={handlePricingClick}
            _hover={{ bg: 'gray.200' }}
            transition="all 0.2s"
          >
            Pricing
          </Button>
        </>
      )}
      {currentUser && (
        <>
          <NavLink to="/dashboard">Dashboard</NavLink>
          <NavLink to="/client-management">Manage Clients</NavLink>
          <CreateInvoiceButton />
        </>
      )}
      {!currentUser ? (
        <>
          <NavLink to="/login">Login</NavLink>
          <CreateFreeInvoiceButton />
        </>
      ) : (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            variant="ghost"
            _hover={{ bg: 'gray.200' }}
            transition="all 0.2s"
          >
            <Avatar size="sm" name={currentUser.email} src={profilePicture || currentUser.photoURL} />
          </MenuButton>
          <MenuList>
            <MenuItem as={Text} fontWeight="bold">{currentUser.email}</MenuItem>
            <MenuItem as={Link} to="/profile" _hover={{ bg: 'gray.200' }} transition="all 0.2s">Profile</MenuItem>
            <MenuItem onClick={handleLogout} _hover={{ bg: 'gray.200' }} transition="all 0.2s">Logout</MenuItem>
          </MenuList>
        </Menu>
      )}
    </>
  );

  return (
    <Box bg="white" py={4} px={8} position="sticky" top={0} zIndex={1000} borderBottom="1px solid" borderColor="gray.200">
      <Flex maxW="container.xl" mx="auto" align="center">
        <Box display="flex" alignItems="center">
          <Link to={currentUser ? "/dashboard" : "/"}>
            <Image src={logo} alt="InvoGen Logo" boxSize="48px" mr={3} />
          </Link>
          <Link to={currentUser ? "/dashboard" : "/"}>
            <Text fontSize="2xl" fontWeight="bold" color="black" letterSpacing="tight">InvoGen</Text>
          </Link>
        </Box>
        <Spacer />
        <Box display={{ base: 'none', md: 'block' }}>
          <NavContent />
        </Box>
        <Box display={{ base: 'block', md: 'none' }}>
          <IconButton
            icon={<HamburgerIcon />}
            onClick={onOpen}
            variant="ghost"
            aria-label="Open Menu"
            _hover={{ bg: 'gray.200' }}
            transition="all 0.2s"
          />
          <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader>
              <DrawerBody>
                <VStack spacing={4} align="stretch">
                  <NavContent isMobile />
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navigation;
