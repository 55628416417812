import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  VStack,
  HStack,
  Text,
  useToast,
  Badge,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useClipboard,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  useDisclosure,
  useBreakpointValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Switch,
  Center,
} from '@chakra-ui/react';
import { DownloadIcon, EmailIcon, EditIcon, DeleteIcon, SettingsIcon, CopyIcon, ChevronUpIcon, ChevronDownIcon, AddIcon } from '@chakra-ui/icons';
import { collection, query, where, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import './InvoiceList.css';

const InvoiceList = ({ filters, onDownload, onSend, totalInvoices, onCreateInvoice }) => {
  const [invoices, setInvoices] = useState([]);
  const [shareableLink, setShareableLink] = useState('');
  const [userPreferredView, setUserPreferredView] = useState('table');
  const [sortField, setSortField] = useState('invoiceDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const toast = useToast();
  const navigate = useNavigate();
  const { onCopy, hasCopied } = useClipboard(shareableLink);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const autoView = useBreakpointValue({ base: 'card', md: 'table' });
  const viewMode = useBreakpointValue({ base: 'card', md: userPreferredView });

  useEffect(() => {
    if (!auth.currentUser) return;

    const q = query(
      collection(db, 'invoices'),
      where('userId', '==', auth.currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const invoicesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setInvoices(invoicesData);
    }, (error) => {
      console.error("Error fetching invoices: ", error);
      toast({
        title: 'Error',
        description: 'Failed to fetch invoices. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    });

    return () => unsubscribe();
  }, [toast]);

  const filteredInvoices = useMemo(() => {
    let result = invoices.filter(invoice => {
      const invoiceDate = new Date(invoice.invoiceDate);
      const startDate = filters.startDate ? new Date(filters.startDate) : null;
      const endDate = filters.endDate ? new Date(filters.endDate) : null;

      return (
        (!startDate || invoiceDate >= startDate) &&
        (!endDate || invoiceDate <= endDate) &&
        (!filters.client || invoice.customerName.toLowerCase().includes(filters.client.toLowerCase())) &&
        (!filters.status || invoice.paymentStatus === filters.status)
      );
    });

    // Sort the filtered invoices
    result.sort((a, b) => {
      if (sortField === 'invoiceDate' || sortField === 'dueDate') {
        return sortDirection === 'asc'
          ? new Date(a[sortField]) - new Date(b[sortField])
          : new Date(b[sortField]) - new Date(a[sortField]);
      } else if (sortField === 'totalAmount') {
        return sortDirection === 'asc'
          ? a.totalAmount - b.totalAmount
          : b.totalAmount - a.totalAmount;
      }
      return 0;
    });

    return result;
  }, [invoices, filters, sortField, sortDirection]);

  const handleDelete = async (invoiceId) => {
    if (!window.confirm('Are you sure you want to delete this invoice?')) return;

    try {
      await deleteDoc(doc(db, 'invoices', invoiceId));
      toast({
        title: 'Invoice deleted',
        description: 'The invoice has been successfully deleted.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting invoice: ", error);
      toast({
        title: 'Error',
        description: 'Failed to delete invoice. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEdit = (invoiceId) => {
    navigate(`/edit-invoice/${invoiceId}`);
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'paid':
        return 'green';
      case 'unpaid':
        return 'yellow';
      case 'overdue':
        return 'red';
      default:
        return 'gray';
    }
  };

  const generateShareableLink = (invoiceId) => {
    return `${window.location.origin}/invoice/public/${invoiceId}`;
  };

  const handleShareLink = (invoiceId) => {
    const link = generateShareableLink(invoiceId);
    setShareableLink(link);
    onOpen();
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const renderSortIcon = (field) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? <ChevronUpIcon/> : <ChevronDownIcon />;
  };

  const renderInvoiceActions = (invoice) => (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<SettingsIcon />}
        variant="outline"
      />
      <MenuList>
        <MenuItem icon={<EditIcon />} onClick={() => handleEdit(invoice.id)}>
          Edit Invoice
        </MenuItem>
        <MenuItem icon={<DownloadIcon />} onClick={() => onDownload(invoice)}>
          Download Invoice
        </MenuItem>
        <MenuItem icon={<EmailIcon />} onClick={() => onSend(invoice)}>
          Send Invoice
        </MenuItem>
        <MenuItem icon={<CopyIcon />} onClick={() => handleShareLink(invoice.id)}>
          Share Invoice Link
        </MenuItem>
        <MenuItem icon={<DeleteIcon />} onClick={() => handleDelete(invoice.id)}>
          Delete Invoice
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const renderInvoiceItem = (invoice) => (
    <Box key={invoice.id} className="invoice-item" borderWidth="1px" borderRadius="lg" p={4} mb={4}>
      <VStack align="stretch" spacing={2}>
        <HStack justify="space-between">
          <Text fontWeight="bold">{new Date(invoice.invoiceDate).toLocaleDateString()}</Text>
          <Badge colorScheme={getStatusColor(invoice.paymentStatus)}>
            {invoice.paymentStatus}
          </Badge>
        </HStack>
        <Text>{invoice.customerName}</Text>
        <Text fontWeight="bold">{invoice.totalAmount} {invoice.currency}</Text>
        <Text>Due Date: {new Date(invoice.dueDate).toLocaleDateString()}</Text>
        <HStack justify="flex-end">
          {renderInvoiceActions(invoice)}
        </HStack>
      </VStack>
    </Box>
  );

  const renderTableView = () => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th cursor="pointer" onClick={() => handleSort('invoiceDate')}>
            Date of Issue {renderSortIcon('invoiceDate')}
          </Th>
          <Th cursor="pointer" onClick={() => handleSort('dueDate')}>
            Due Date {renderSortIcon('dueDate')}
          </Th>
          <Th>Customer</Th>
          <Th cursor="pointer" onClick={() => handleSort('totalAmount')}>
            Amount {renderSortIcon('totalAmount')}
          </Th>
          <Th>Status</Th>
          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredInvoices.map(invoice => (
          <Tr key={invoice.id}>
            <Td>{new Date(invoice.invoiceDate).toLocaleDateString()}</Td>
            <Td>{new Date(invoice.dueDate).toLocaleDateString()}</Td>
            <Td>{invoice.customerName}</Td>
            <Td>{invoice.totalAmount} {invoice.currency}</Td>
            <Td>
              <Badge colorScheme={getStatusColor(invoice.paymentStatus)}>
                {invoice.paymentStatus}
              </Badge>
            </Td>
            <Td>{renderInvoiceActions(invoice)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  const renderCardView = () => (
    <VStack spacing={4} align="stretch">
      {filteredInvoices.map(renderInvoiceItem)}
    </VStack>
  );

  const renderNoInvoicesMessage = () => (
    <Center height="200px" flexDirection="column">
      <VStack spacing={4}>
        <Text fontSize="xl" fontWeight="bold" color="gray.500">
          No invoices found
        </Text>
        <Text color="gray.500">
          {totalInvoices === 0
            ? "You haven't created any invoices yet. Start by creating your first invoice!"
            : "No invoices match the current filters."}
        </Text>
      </VStack>
      {totalInvoices === 0 && (
        <Button
          leftIcon={<AddIcon />}
          onClick={onCreateInvoice}
          mt={4}
          colorScheme="blue"
          size="lg"
        >
          Create Your First Invoice
        </Button>
      )}
    </Center>
  );

  return (
    <Box>
      <HStack justify="space-between" mb={4}>
        <Heading as="h2" size="lg">Invoices</Heading>
        {autoView === 'table' && totalInvoices > 0 && (
          <HStack>
            <Text>{userPreferredView === 'card' ? 'Card' : 'Table'} View</Text>
            <Switch
              isChecked={userPreferredView === 'table'}
              onChange={() => setUserPreferredView(userPreferredView === 'card' ? 'table' : 'card')}
            />
          </HStack>
        )}
      </HStack>
      
      {filteredInvoices.length > 0 ? (
        viewMode === 'card' ? renderCardView() : renderTableView()
      ) : (
        renderNoInvoicesMessage()
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Shareable Invoice Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={2}>You can share this link with anyone to view the invoice:</Text>
            <Input value={shareableLink} isReadOnly />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCopy}>
              {hasCopied ? 'Copied!' : 'Copy to Clipboard'}
            </Button>
            <Button variant="ghost" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InvoiceList;
