import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../auth/Login';
import Register from '../auth/Register';
import Profile from '../auth/Profile';
import ProtectedRoute from './ProtectedRoute';
import SendInvoice from '../utils/SendInvoice';
import Dashboard from '../dashboard/Dashboard';
import InvoiceCreation from '../invoice-creation/InvoiceCreation';
import ClientManagement from '../client/ClientManagement';
import { useAuth } from '../../contexts/AuthContext';
import LandingPage from '../marketing/LandingPage';
import Blogs from '../blogs/Blogs';
import BlogPost from '../blogs/BlogPost';
import PublicInvoiceView from '../utils/PublicInvoiceView';
import FreeInvoiceCreation from '../free-invoice-creation/InvoiceCreation';

const AppRoutes = () => {
  const { currentUser } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:id" element={<BlogPost />} />
      <Route path="/invoice/public/:id" element={<PublicInvoiceView />} />
      <Route path="/free-invoice-creation/*" element={<FreeInvoiceCreation />}>
        <Route path="sender-details" element={<FreeInvoiceCreation />} />
        <Route path="client-selection" element={<FreeInvoiceCreation />} />
        <Route path="invoice-form" element={<FreeInvoiceCreation />} />
        <Route path="item-list" element={<FreeInvoiceCreation />} />
        <Route path="final-review" element={<FreeInvoiceCreation />} />
      </Route>
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/send-invoice"
        element={
          <ProtectedRoute>
            <SendInvoice />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-invoice/*"
        element={
          <ProtectedRoute>
            <InvoiceCreation />
          </ProtectedRoute>
        }
      >
        <Route path="sender-details" element={<InvoiceCreation />} />
        <Route path="client-selection" element={<InvoiceCreation />} />
        <Route path="invoice-form" element={<InvoiceCreation />} />
        <Route path="item-list" element={<InvoiceCreation />} />
        <Route path="final-review" element={<InvoiceCreation />} />
      </Route>
      <Route
        path="/edit-invoice/:invoiceId/*"
        element={
          <ProtectedRoute>
            <InvoiceCreation />
          </ProtectedRoute>
        }
      >
        <Route path="sender-details" element={<InvoiceCreation />} />
        <Route path="client-selection" element={<InvoiceCreation />} />
        <Route path="invoice-form" element={<InvoiceCreation />} />
        <Route path="item-list" element={<InvoiceCreation />} />
        <Route path="final-review" element={<InvoiceCreation />} />
      </Route>
      <Route
        path="/client-management"
        element={
          <ProtectedRoute>
            <ClientManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          currentUser ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />
        }
      />
    </Routes>
  );
};

export default AppRoutes;
