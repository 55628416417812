import React from 'react';
import { Box, Heading, Text, Button, Center, VStack, Icon, Flex, chakra, keyframes, Container, SimpleGrid, useBreakpointValue, Stack, useMediaQuery, Image } from '@chakra-ui/react';
import {FaFileInvoice, FaUsers, FaChartBar, FaDownload, FaLink, FaEnvelope, FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import FAQ from './FAQ';

// Import the images
import dashboardImage from '../../assets/dashboard.png';
import clientManageImage from '../../assets/client_management.png';
import itemsInvoiceImage from '../../assets/items_invoice.png';
import previewImage from '../../assets/preview.png';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/register');
  };

  const handleCreateFreeInvoiceClick = () => {
    navigate('/free-invoice-creation');
  };

  const shimmer = keyframes`
    0% { background-position: -200% center; }
    100% { background-position: 200% center; }
  `;

  const StylizedHeading = chakra(Text, {
    baseStyle: {
      fontSize: ["2xl", "3xl", "4xl", "5xl", "6xl"],
      fontWeight: "extrabold",
      bgGradient: "linear(to-r, #000000, #333333, #666666)",
      bgClip: "text",
      backgroundSize: "200% auto",
      animation: `${shimmer} 5s linear infinite`,
      textShadow: "0px 2px 4px rgba(0,0,0,0.1)",
      letterSpacing: "tight",
      textAlign: "center",
      lineHeight: 1.2,
      mb: 4,
    },
  });

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const sectionSpacing = useBreakpointValue({ base: 8, sm: 10, md: 12, lg: 16 });
  const cardColumns = useBreakpointValue({ base: 1, sm: 2, lg: 3 });

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="space-between">
      <Container maxW="container.xl" px={[4, 6, 8]}>
        <Center py={sectionSpacing} color="black">
          <VStack spacing={6} width="100%">
            <StylizedHeading>
              InvoGen
            </StylizedHeading>
            <Text fontSize={["md", "lg", "xl"]} color="gray.700" textAlign="center" px={[2, 4, 6]}>
              Your one-stop solution for invoice generation and management.
            </Text>
            <Flex direction={["column", "row"]} gap={4} width="100%" justifyContent="center">
              <Button 
                bg="black" 
                color="white" 
                size={buttonSize}
                onClick={handleGetStartedClick} 
                _hover={{ bg: "gray.800" }}
                width={["100%", "auto"]}
              >
                Get Started
              </Button>
              <Button 
                bg="blue.500" 
                color="white" 
                size={buttonSize}
                onClick={handleCreateFreeInvoiceClick} 
                _hover={{ bg: "blue.600" }}
                width={["100%", "auto"]}
              >
                Create Free Invoice
              </Button>
            </Flex>
          </VStack>
        </Center>

        <Box id="features" py={sectionSpacing} bg="white" borderRadius="lg" mb={sectionSpacing}>
          <VStack spacing={10} w="full">
            <Heading as="h2" size={["lg", "xl"]} textAlign="center" color="black">Features</Heading>
            <SimpleGrid columns={cardColumns} spacing={8} px={[4, 6, 8]}>
              <FeatureCard icon={FaEnvelope} title="Send Invoices via Email" description="Easily send invoices to clients via email." />
              <FeatureCard icon={FaDownload} title="PDF Generation" description="Generate and download PDF versions of invoices." />
              <FeatureCard icon={FaLink} title="Shareable Links" description="Generate shareable links for invoices." />
              <FeatureCard icon={FaFileInvoice} title="Invoice Management" description="Create, edit, send, and manage invoices." />
              <FeatureCard icon={FaUsers} title="Client Management" description="Keep track of your clients." />
              <FeatureCard icon={FaChartBar} title="Dashboard" description="Get an overview of your activities and data." />
            </SimpleGrid>
          </VStack>
        </Box>
        
        <ProductShowcase sectionSpacing={sectionSpacing} />

        <Box id="testimonials" py={sectionSpacing} bg="white" borderRadius="lg" mb={sectionSpacing}>
          <VStack spacing={10} w="full">
            <Heading as="h2" size={["lg", "xl"]} textAlign="center" color="black">Testimonials</Heading>
            <SimpleGrid columns={cardColumns} spacing={8} px={[4, 6, 8]}>
              <TestimonialCard
                name="Zo Jenson"
                role="Freelancer"
                testimonial="It has made my invoicing process so much easier. I can create and send invoices in minutes!"
              />
              <TestimonialCard
                name="Jack Johnson"
                role="Small Business Owner"
                testimonial="As someone who doesn't have a lot of tech skills, I was a bit unsure about using this at first. But it's incredibly easy to use, even for beginners like me! Highly recommend it for anyone looking for a simple solution."
              />
              <TestimonialCard
                name="Lina Harris"
                role="Accountant"
                testimonial="The client management feature saves me a lot of time. I can keep track of all my clients in one place."
              />
            </SimpleGrid>
          </VStack>
        </Box>

        <Box id="faq" py={sectionSpacing} bg="white" borderRadius="lg" mb={sectionSpacing}>
          <FAQ />
        </Box>

        <Box id="pricing" py={sectionSpacing} bg="white" borderRadius="lg" mb={sectionSpacing}>
          <VStack spacing={10} w="full">
            <Heading as="h2" size={["lg", "xl"]} textAlign="center" color="black">Pricing</Heading>
            <Center>
              <PricingCard
                title="All Features Free"
                price="$0"
                features={[
                  "Unlimited invoices",
                  "Unlimited emails",
                  "Client management",
                  "Customer support",
                  "PDF generation",
                  "Shareable links"
                ]}
                buttonText="Get Started"
                onClick={handleGetStartedClick}
                highlighted={true}
              />
            </Center>
          </VStack>
        </Box>
      </Container>

      <Box as="footer" py={4} color="black" textAlign="center" borderTop="1px" borderColor="gray.200">
        <Text>&copy; {new Date().getFullYear()} InvoGen. All rights reserved.</Text>
      </Box>
    </Box>
  );
};

const FeatureCard = ({ icon, title, description }) => (
  <Box 
    p={6} 
    bg="white" 
    border="1px" 
    borderColor="gray.200" 
    borderRadius="lg" 
    textAlign="center" 
    transition="all 0.3s"
    _hover={{
      transform: "translateY(-5px)",
      boxShadow: "xl",
      borderColor: "black"
    }}
  >
    <Icon as={icon} w={8} h={8} color="black" mb={4} />
    <Heading as="h3" size="md" mb={3} color="black">{title}</Heading>
    <Text fontSize="sm" color="gray.600">{description}</Text>
  </Box>
);

const TestimonialCard = ({ name, role, testimonial }) => {
  const [isLargerThan48em] = useMediaQuery("(min-width: 48em)");

  return (
    <Box 
      p={[4, 6]} 
      bg="white" 
      border="1px" 
      borderColor="gray.200" 
      borderRadius="lg" 
      textAlign="center"
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-5px)",
        boxShadow: "xl",
        borderColor: "black"
      }}
      height={isLargerThan48em ? "100%" : "auto"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Text fontSize={["sm", "md"]} fontStyle="italic" mb={4} color="gray.700" flexGrow={1}>"{testimonial}"</Text>
      <Box>
        <Text fontWeight="bold" color="black">{name}</Text>
        <Text fontSize={["xs", "sm"]} color="gray.600">{role}</Text>
      </Box>
    </Box>
  );
};

const PricingCard = ({ title, price, features, buttonText, onClick, highlighted = false }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    p={6}
    textAlign="center"
    bg={highlighted ? "black" : "white"}
    color={highlighted ? "white" : "black"}
    boxShadow={highlighted ? "xl" : "md"}
    position="relative"
    transition="all 0.3s"
    _hover={{
      transform: "translateY(-5px)",
      boxShadow: "xl",
    }}
  >
    <Heading as="h3" size="lg" mb={2}>
      {title}
    </Heading>
    <Text fontSize="4xl" fontWeight="bold" mb={2}>
      {price}
    </Text>
    <Stack spacing={3} mb={6}>
      {features.map((feature, index) => (
        <Flex key={index} align="center" justify="center">
          <Icon as={FaCheck} color={highlighted ? "blue.300" : "blue.500"} mr={2} />
          <Text>{feature}</Text>
        </Flex>
      ))}
    </Stack>
    <Button
      onClick={onClick}
      colorScheme={highlighted ? "blue" : "gray"}
      variant={highlighted ? "solid" : "outline"}
      size="lg"
      width="full"
    >
      {buttonText}
    </Button>
  </Box>
);

const ProductShowcase = ({ sectionSpacing }) => (
  <Box id="product-showcase" py={sectionSpacing} bg="white" borderRadius="lg" mb={sectionSpacing}>
    <VStack spacing={16} w="full" maxW="container.lg" mx="auto">
      <Heading as="h2" size={["lg", "xl"]} textAlign="center" color="black">Product Highlights</Heading>
      <VStack spacing={16} w="full">
        <ShowcaseItem
          image={dashboardImage}
          title="Intuitive Dashboard"
          description="Get a quick overview of your invoices and financial status."
        />
        <ShowcaseItem
          image={clientManageImage}
          title="Client Management"
          description="Easily manage your clients and their information."
        />
        <ShowcaseItem
          image={itemsInvoiceImage}
          title="Detailed Invoices"
          description="Create professional, detailed invoices with ease."
        />
        <ShowcaseItem
          image={previewImage}
          title="Invoice Preview"
          description="Preview your invoices before sending them to clients."
        />
      </VStack>
    </VStack>
  </Box>
);

const ShowcaseItem = ({ image, title, description }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    transition="all 0.3s"
    _hover={{
      transform: "translateY(-5px)",
      boxShadow: "xl",
    }}
    w="full"
  >
    <Image src={image} alt={title} width="100%" height="auto" objectFit="cover" />
    <Box p={6}>
      <Heading as="h3" size="md" mb={2}>{title}</Heading>
      <Text color="gray.600">{description}</Text>
    </Box>
  </Box>
);

export default LandingPage;
