import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Checkbox,
} from '@chakra-ui/react';

export const UnsavedChangesAlert = ({ isOpen, onClose, onDiscard, cancelRef }) => (
  <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={onClose}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Discard Changes?
        </AlertDialogHeader>
        <AlertDialogBody>
          Are you sure you want to discard your unsaved changes?
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onDiscard} ml={3}>
            Discard
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
);

export const ConfirmDraftModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Confirm Save as Draft</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        Are you sure you want to save this invoice as a draft?
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onConfirm}>
          Save as Draft
        </Button>
        <Button variant="ghost" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export const SendInvoiceModal = ({ isOpen, onClose, onConfirm, isAgreedToTerms, setIsAgreedToTerms }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Send Invoice</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text mb={4}>Are you sure you want to send this invoice to the client?</Text>
        <Checkbox isChecked={isAgreedToTerms} onChange={(e) => setIsAgreedToTerms(e.target.checked)}>
          I confirm that all the information in this invoice is correct and ready to be sent.
        </Checkbox>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="green" mr={3} onClick={onConfirm} isDisabled={!isAgreedToTerms}>
          Send Invoice
        </Button>
        <Button variant="ghost" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);