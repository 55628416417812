import React, { useState, useEffect } from 'react';
import {
  FormControl,
  Input,
  Button,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  VStack,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon } from '@chakra-ui/icons';

const ItemList = ({ invoiceData, setInvoiceData }) => {
  const [newItem, setNewItem] = useState({ itemName: '', quantity: '', price: '', total: 0 });
  const toast = useToast();

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    const updatedItem = { ...newItem, [name]: value };
    updatedItem.total = updatedItem.quantity * updatedItem.price;
    setNewItem(updatedItem);
  };

  const addItem = () => {
    if (newItem.itemName && newItem.quantity > 0 && newItem.price > 0) {
      setInvoiceData(prev => {
        const updatedItems = [...prev.items, newItem];
        const newSubtotal = calculateSubtotal(updatedItems);
        const newTotalAmount = calculateTotalAmount(newSubtotal, prev.taxRate, prev.deductions);
        return {
          ...prev,
          items: updatedItems,
          subtotal: newSubtotal,
          totalAmount: newTotalAmount
        };
      });
      setNewItem({ itemName: '', quantity: '', price: '', total: 0 });
    } else {
      toast({
        title: 'Invalid item',
        description: 'Please fill all item fields correctly.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const removeItem = (index) => {
    setInvoiceData(prev => {
      const updatedItems = prev.items.filter((_, i) => i !== index);
      const newSubtotal = calculateSubtotal(updatedItems);
      const newTotalAmount = calculateTotalAmount(newSubtotal, prev.taxRate, prev.deductions);
      return {
        ...prev,
        items: updatedItems,
        subtotal: newSubtotal,
        totalAmount: newTotalAmount
      };
    });
  };

  const handleTaxRateChange = (e) => {
    const newTaxRate = parseFloat(e.target.value) || 0;
    setInvoiceData(prev => ({
      ...prev,
      taxRate: newTaxRate,
      totalAmount: calculateTotalAmount(prev.subtotal, newTaxRate, prev.deductions)
    }));
  };

  const handleDeductionsChange = (e) => {
    const newDeductions = parseFloat(e.target.value) || 0;
    setInvoiceData(prev => ({
      ...prev,
      deductions: newDeductions,
      totalAmount: calculateTotalAmount(prev.subtotal, prev.taxRate, newDeductions)
    }));
  };

  const calculateSubtotal = (items) => {
    return items.reduce((acc, item) => acc + Number(item.total), 0);
  };

  const calculateTotalAmount = (subtotal, taxRate, deductions) => {
    const taxAmount = subtotal * (Number(taxRate) / 100);
    return subtotal + taxAmount - Number(deductions);
  };

  useEffect(() => {
    const newSubtotal = calculateSubtotal(invoiceData.items);
    const newTotalAmount = calculateTotalAmount(newSubtotal, invoiceData.taxRate, invoiceData.deductions);
    if (newSubtotal !== invoiceData.subtotal || newTotalAmount !== invoiceData.totalAmount) {
      setInvoiceData(prev => ({
        ...prev,
        subtotal: newSubtotal,
        totalAmount: newTotalAmount
      }));
    }
  }, [invoiceData.items, invoiceData.taxRate, invoiceData.deductions]);

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <HStack>
          <Input placeholder="Item name" name="itemName" value={newItem.itemName} onChange={handleItemChange} />
          <Input
            placeholder="Quantity"
            name="quantity"
            type="number"
            value={newItem.quantity}
            onChange={handleItemChange}
          />
          <Input
            placeholder="Price"
            name="price"
            type="number"
            value={newItem.price}
            onChange={handleItemChange}
          />
          <Button onClick={addItem} colorScheme='green'><AddIcon size="sm"/></Button>
        </HStack>
      </FormControl>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Quantity</Th>
            <Th>Price</Th>
            <Th>Total</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoiceData.items.map((item, index) => (
            <Tr key={index}>
              <Td>{item.itemName}</Td>
              <Td>{item.quantity}</Td>
              <Td>{Number(item.price).toFixed(2)}</Td>
              <Td>{Number(item.total).toFixed(2)}</Td>
              <Td>
                <Button size="sm" colorScheme="red" onClick={() => removeItem(index)}>
                  <DeleteIcon size="sm"/>
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <HStack justify="flex-end">
        <Text fontWeight="bold">Subtotal:</Text>
        <Text>{(invoiceData.subtotal || 0).toFixed(2)}</Text>
      </HStack>
      <HStack>
        <FormControl>
          <HStack>
            <Text fontWeight="bold">Tax Rate (%):</Text>
            <Input
              type="number"
              value={invoiceData.taxRate || 0}
              onChange={handleTaxRateChange}
              width="100px"
            />
          </HStack>
        </FormControl>
        <FormControl>
          <HStack>
            <Text fontWeight="bold">Deductions:</Text>
            <Input
              type="number"
              value={invoiceData.deductions || 0}
              onChange={handleDeductionsChange}
              width="100px"
            />
          </HStack>
        </FormControl>
      </HStack>
      <HStack justify="flex-end">
        <Text fontWeight="bold">Total Amount:</Text>
        <Text>{(invoiceData.totalAmount || 0).toFixed(2)}</Text>
      </HStack>
    </VStack>
  );
};

export default ItemList;