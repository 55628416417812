import React from 'react';
import { Box } from '@chakra-ui/react';
import InvoiceForm from './InvoiceForm';

const InvoiceFormStep = ({ invoiceData, handleInputChange }) => {
  return (
    <Box>
      <InvoiceForm
        invoiceData={invoiceData}
        handleInputChange={handleInputChange}
      />
    </Box>
  );
};

export default InvoiceFormStep;