import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider } from '../../firebase';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  useToast,
  Image,
  InputGroup,
  InputRightElement,
  Spinner,
  Divider,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import logo from '../../assets/invogen.png';

export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { signup } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      setError('');
      await signup(email, password);
      navigate('/dashboard');
    } catch {
      setError('Failed to create an account');
      toast({
        title: 'Registration failed.',
        description: 'Please check your email and password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  async function handleGoogleSignUp() {
    setLoading(true);
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/dashboard');
    } catch (error) {
      setError('Failed to sign up with Google');
      toast({
        title: 'Google Sign-Up failed.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={[4, 8, 12]}
      bg="white"
      minHeight="100vh"
    >
      <Box
        as="form"
        onSubmit={handleSubmit}
        bg="white"
        p={[4, 6, 8]}
        shadow="2xl"
        width="100%"
        maxWidth={["100%", "400px"]}
        borderRadius="lg"
      >
        <VStack spacing={[4, 5, 6]} align="center">
          <Image src={logo} alt="Invogen Logo" boxSize={["50px", "60px", "70px"]} mb={[2, 3, 4]} />
          <Heading as="h2" size={["md", "lg"]} textAlign="center" color="black" fontWeight="bold">
            Create an Account
          </Heading>
          {error && <Text color="red.500" fontWeight="medium">{error}</Text>}
          
          {/* Email Input */}
          <FormControl id="email" isRequired>
            <FormLabel color="black">Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="username"
              bgColor="white"
              borderColor="gray.200"
              borderRadius="md"
              _focus={{ borderColor: 'gray.300', boxShadow: '0 0 0 1px gray.300' }}
            />
          </FormControl>

          {/* Password Input */}
          <FormControl id="password" isRequired>
            <FormLabel color="black">Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="new-password"
                bgColor="white"
                borderColor="gray.200"
                borderRadius="md"
                _focus={{ borderColor: 'gray.300', boxShadow: '0 0 0 1px gray.300' }}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)} bg="gray.100" color="black">
                  {showPassword ? 'Hide' : 'Show'}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>

          {/* Register Button */}
          <Button
            type="submit"
            bg="black"
            color="white"
            width="full"
            isDisabled={loading}
            _hover={{ bg: 'gray.800' }}
          >
            {loading ? <Spinner size="sm" /> : 'Register'}
          </Button>

          <Divider />

          {/* Google Sign-Up Button */}
          <Button
            leftIcon={<FcGoogle />}
            onClick={handleGoogleSignUp}
            width="full"
            variant="outline"
            isDisabled={loading}
            borderColor="gray.200"
            color="black"
            _hover={{ bg: 'gray.50' }}
          >
            Sign up with Google
          </Button>
          
          {/* Login Link */}
          <Text fontSize={["xs", "sm"]} color="gray.700">
            Already have an account?{' '}
            <Link to="/login" style={{ color: 'black', fontWeight: 'bold' }}>
              Login
            </Link>
          </Text>
        </VStack>
      </Box>
    </Box>
  );
}
