import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  VStack,
  HStack,
  useToast,
  Textarea,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react';

export default function Profile() {
  const [companyName, setCompanyName] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [businessAddress, setBusinessAddress] = useState('');
  const [taxId, setTaxId] = useState('');
  const [bio, setBio] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const { currentUser } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();

  console.log('Profile component rendered. Current user:', currentUser);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        console.log('Fetching user profile for:', currentUser?.uid);
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          console.log('User data fetched:', userData);
          setCompanyName(userData.companyName || '');
          setContactInfo(userData.contactInfo || '');
          setBusinessAddress(userData.businessAddress || '');
          setTaxId(userData.taxId || '');
          setBio(userData.bio || '');
        } else {
          console.log('No user data found in Firestore');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        toast({
          title: 'Error',
          description: 'Failed to load user profile.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (currentUser) {
      fetchUserProfile();
    } else {
      console.log('No current user, skipping profile fetch');
      setIsLoading(false);
    }
  }, [currentUser, toast]);

  function validateForm() {
    const newErrors = {};
    if (!companyName) newErrors.companyName = 'Company name is required';
    if (!contactInfo) newErrors.contactInfo = 'Contact info is required';
    if (!businessAddress) newErrors.businessAddress = 'Business address is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        console.log('Updating user profile for:', currentUser.uid);
        const userDocRef = doc(db, 'users', currentUser.uid);
        await setDoc(userDocRef, {
          companyName,
          contactInfo,
          businessAddress,
          taxId,
          bio,
        }, { merge: true });

        console.log('Profile updated successfully');
        toast({
          title: 'Profile updated',
          description: 'Your profile has been successfully updated.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        
        // Redirect to dashboard after successful update
        navigate('/dashboard');
      } catch (error) {
        console.error('Error updating profile:', error);
        toast({
          title: 'Error',
          description: 'Failed to update profile. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function handleCancel() {
    setIsLoading(true);
    try {
      console.log('Reverting profile changes for:', currentUser.uid);
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setCompanyName(userData.companyName || '');
        setContactInfo(userData.contactInfo || '');
        setBusinessAddress(userData.businessAddress || '');
        setTaxId(userData.taxId || '');
        setBio(userData.bio || '');
      }
      setErrors({});
    } catch (error) {
      console.error('Error reverting profile changes:', error);
      toast({
        title: 'Error',
        description: 'Failed to revert changes. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box p={8} maxWidth="800px" margin="0 auto">
      <Heading as="h2" size="xl" mb={6}>
        Profile
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={6} align="stretch">
          <FormControl isInvalid={errors.companyName}>
            <FormLabel>Company Name</FormLabel>
            <Input
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <FormErrorMessage>{errors.companyName}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.contactInfo}>
            <FormLabel>Contact Info</FormLabel>
            <Input
              type="text"
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
            />
            <FormErrorMessage>{errors.contactInfo}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.businessAddress}>
            <FormLabel>Business Address</FormLabel>
            <Input
              type="text"
              value={businessAddress}
              onChange={(e) => setBusinessAddress(e.target.value)}
            />
            <FormErrorMessage>{errors.businessAddress}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel>Tax ID</FormLabel>
            <Input
              type="text"
              value={taxId}
              onChange={(e) => setTaxId(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Bio</FormLabel>
            <Textarea
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              rows={4}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Text>{currentUser.email}</Text>
          </FormControl>
        </VStack>
        <HStack mt={6} spacing={4}>
          <Button type="submit" colorScheme="blue" isLoading={isLoading}>
            Update Profile
          </Button>
          <Button onClick={handleCancel} variant="outline">
            Cancel
          </Button>
        </HStack>
      </form>
    </Box>
  );
}
