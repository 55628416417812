import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Text, Button, VStack, HStack, Link, Grid, Image, Tag } from '@chakra-ui/react';
import { FaRegClock, FaRegUser } from 'react-icons/fa';

const BlogCard = ({ blog }) => (
  <Box borderWidth="1px" borderRadius="lg" overflow="hidden" shadow="md" transition="all 0.3s" _hover={{ transform: 'translateY(-5px)', shadow: 'lg' }}>
    <Image src={blog.image} alt={blog.title} objectFit="cover" height="200px" width="100%" />
    <VStack align="start" spacing={3} p={4}>
      <Tag colorScheme="blue">{blog.category}</Tag>
      <Heading as="h2" size="md" noOfLines={2}>{blog.title}</Heading>
      <HStack fontSize="sm" color="gray.500">
        <HStack>
          <FaRegClock />
          <Text>{blog.readTime} min read</Text>
        </HStack>
        <HStack>
          <FaRegUser />
          <Text>{blog.author}</Text>
        </HStack>
      </HStack>
      {blog.id && (
        <Link as={RouterLink} to={`/blogs/${blog.id}`} width="100%">
          <Button colorScheme="blue" width="100%">Read More</Button>
        </Link>
      )}
    </VStack>
  </Box>
);

const BlogCards = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const importBlogs = () => {
      const context = require.context('./blogList', false, /\.js$/);
      const blogModules = context.keys().map(key => {
        const module = context(key);
        return module.default;  // Each module exports the blog object as default
      });
      
      setBlogs(blogModules.filter(blog => blog && blog.id));  // Filter out any invalid blog posts
    };

    importBlogs();
  }, []);

  return (
    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={8}>
      {blogs.map((blog) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </Grid>
  );
};

export default BlogCards;
