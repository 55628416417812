import React, { useState, useEffect, useCallback } from 'react';
import { storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Button,
  Image,
  Text,
  VStack,
  HStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

const LogoUpload = ({ onLogoSelect, isDisabled }) => {
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [uploadedLogos, setUploadedLogos] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { currentUser } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchUploadedLogos = useCallback(async () => {
    if (currentUser) {
      try {
        const logosRef = ref(storage, `logos/${currentUser.uid}`);
        const logosList = await listAll(logosRef);
        const urls = await Promise.all(
          logosList.items.map((item) => getDownloadURL(item))
        );
        setUploadedLogos(urls);
      } catch (error) {
        console.error('Error fetching logos:', error);
        toast({
          title: 'Error fetching logos',
          description: 'Please try again later.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [currentUser, toast]);

  useEffect(() => {
    fetchUploadedLogos();
  }, [fetchUploadedLogos]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0] && !isDisabled) {
      const file = acceptedFiles[0];
      if (file.type.startsWith('image/')) {
        setLogo(file);
        setLogoUrl(URL.createObjectURL(file));
      } else {
        toast({
          title: 'Invalid file type',
          description: 'Please upload an image file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [toast, isDisabled]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.svg']
    },
    multiple: false,
    disabled: isDisabled,
  });

  const handleLogoUpload = async () => {
    if (logo && !isDisabled) {
      setIsUploading(true);
      try {
        const logoRef = ref(storage, `logos/${currentUser.uid}/${logo.name}`);
        await uploadBytes(logoRef, logo);
        const url = await getDownloadURL(logoRef);
        setLogoUrl(url);
        onLogoSelect(url);
        await fetchUploadedLogos();
        toast({
          title: 'Logo uploaded successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error uploading logo:', error);
        toast({
          title: 'Error uploading logo',
          description: 'Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleLogoSelect = (url) => {
    setLogoUrl(url);
    onLogoSelect(url);
    onClose();
  };

  return (
    <VStack spacing={4} align="stretch">
      <Box
        {...getRootProps()}
        borderWidth={2}
        borderStyle="dashed"
        borderRadius="md"
        p={4}
        textAlign="center"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        bg={isDragActive ? 'gray.100' : 'white'}
        opacity={isDisabled ? 0.5 : 1}
      >
        <input {...getInputProps()} disabled={isDisabled} />
        {logoUrl ? (
          <Image src={logoUrl} alt="Selected logo" maxH="100px" mx="auto" />
        ) : (
          <Text>{isDisabled ? 'Please log in to attach a logo' : 'Drag and drop a logo here, or click to select a file'}</Text>
        )}
      </Box>
      <HStack justifyContent="space-between">
        <Button
          onClick={handleLogoUpload}
          colorScheme="blue"
          isDisabled={!logo || isUploading || isDisabled}
        >
          {isUploading ? <Spinner size="sm" /> : 'Upload Logo'}
        </Button>
        {!isDisabled && (
          <Button onClick={onOpen} variant="outline">
            View Uploaded Logos
          </Button>
        )}
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Previously Uploaded Logos</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={3} spacing={4}>
              {uploadedLogos.map((url, index) => (
                <Box
                  key={index}
                  borderWidth={1}
                  borderRadius="md"
                  p={2}
                  cursor="pointer"
                  onClick={() => handleLogoSelect(url)}
                  _hover={{ boxShadow: 'md' }}
                >
                  <Image src={url} alt={`Logo ${index + 1}`} maxH="100px" mx="auto" />
                </Box>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

export default LogoUpload;
