import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  Box,
  SimpleGrid,
} from '@chakra-ui/react';

const InvoiceForm = ({ invoiceData, handleInputChange, readOnly }) => {
  return (
    <Box width="100%" height="100%" overflowY="auto" pr={4}>
      <VStack spacing={4} align="stretch">
        <SimpleGrid columns={2} spacing={4}>
          <FormControl>
            <FormLabel>Invoice Number</FormLabel>
            <Input name="invoiceNumber" value={invoiceData.invoiceNumber} onChange={handleInputChange} readOnly={readOnly} />
          </FormControl>
          <FormControl>
            <FormLabel>Currency</FormLabel>
            <Select name="currency" value={invoiceData.currency} onChange={handleInputChange} isDisabled={readOnly}>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
            </Select>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Invoice Date</FormLabel>
            <Input name="invoiceDate" type="date" value={invoiceData.invoiceDate} onChange={handleInputChange} readOnly={readOnly} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Due Date</FormLabel>
            <Input name="dueDate" type="date" value={invoiceData.dueDate} onChange={handleInputChange} readOnly={readOnly} />
          </FormControl>
          <FormControl>
            <FormLabel>Payment Status</FormLabel>
            <Select name="paymentStatus" value={invoiceData.paymentStatus} onChange={handleInputChange} isDisabled={readOnly}>
              <option value="Unpaid">Unpaid</option>
              <option value="Paid">Paid</option>
              <option value="Overdue">Overdue</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Payment Method</FormLabel>
            <Input name="paymentMethod" value={invoiceData.paymentMethod} onChange={handleInputChange} readOnly={readOnly} />
          </FormControl>
        </SimpleGrid>
        <FormControl>
          <FormLabel>Additional Details</FormLabel>
          <Textarea
            name="additionalDetails"
            value={invoiceData.additionalDetails}
            onChange={handleInputChange}
            readOnly={readOnly}
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default InvoiceForm;
