import React, { useState } from 'react';
import { Box, Container, VStack, HStack, Input, Button, Grid, useColorModeValue } from '@chakra-ui/react';
import BlogCards from './BlogCards';
import BlogHero from './BlogHero';
import BlogSidebar from './BlogSidebar';
import BackToTopButton from '../utils/BackToTopButton';

const Blogs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const handleSearch = () => {
    // Implement search functionality here
    console.log('Searching for:', searchTerm);
  };

  return (
    <Box bg={bgColor} minH="100vh">
      <BlogHero />
      <Container maxW="container.xl" py={10}>
        <VStack spacing={8} align="stretch">
          <Box borderWidth="1px" borderRadius="lg" p={4} borderColor={borderColor}>
            <HStack>
              <Input
                placeholder="Search blogs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button colorScheme="blue" onClick={handleSearch}>
                Search
              </Button>
            </HStack>
          </Box>
          <Grid templateColumns={{ base: "1fr", lg: "3fr 1fr" }} gap={8}>
            <VStack align="stretch" spacing={8}>
              <BlogCards />
              <Button colorScheme="blue" size="lg" width="100%">
                Load More
              </Button>
            </VStack>
            <BlogSidebar />
          </Grid>
        </VStack>
      </Container>
      <BackToTopButton />
    </Box>
  );
};

export default Blogs;
