import React from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

const FAQ = () => {
  const faqItems = [
    {
      question: "What is InvoGen?",
      answer: "InvoGen is a simple invoice generation and management solution designed to speed up your invoicing process. It is particularly designed to cater to small businesses and freelancers."
    },
    {
      question: "What are the top features in InvoGen?",
      answer: "InvoGen enables you to generate invoices, download them in PDF format, and send them directly to your clients via email. These features are all unlimited."
    },
    {
      question: "What long does it take to create an invoice on InvoGen?",
      answer: "If you have all the information, invoice generation itself takes less than a minute."
    },
  ];

  return (
    <Box py={12} bg="white">
      <Heading as="h2" size="xl" textAlign="center" color="black" mb={8}>
        Frequently Asked Questions
      </Heading>
      <Accordion allowMultiple maxW="4xl" mx="auto">
        {faqItems.map((item, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton _expanded={{ bg: 'gray.100' }}>
                <Box flex="1" textAlign="left" fontWeight="semibold">
                  {item.question}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {item.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default FAQ;
