import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Checkbox,
} from '@chakra-ui/react';

export const UnsavedChangesAlert = ({ isOpen, onClose, onDiscard, cancelRef }) => (
  <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={onClose}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Discard Changes?
        </AlertDialogHeader>

        <AlertDialogBody>
          You have unsaved changes. Are you sure you want to discard them?
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onDiscard} ml={3}>
            Discard
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
);

export const ConfirmDraftModal = ({ isOpen, onClose, onConfirm }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Save as Draft</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        Are you sure you want to save this invoice as a draft?
      </ModalBody>

      <ModalFooter>
        <Button colorScheme="blue" mr={3} onClick={onConfirm}>
          Save Draft
        </Button>
        <Button variant="ghost" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export const SendInvoiceModal = ({ isOpen, onClose, onConfirm, isAgreedToTerms, setIsAgreedToTerms }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Send Invoice</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        Are you sure you want to send this invoice?
        <Checkbox 
          isChecked={isAgreedToTerms} 
          onChange={(e) => setIsAgreedToTerms(e.target.checked)}
          mt={4}
        >
          I agree to the terms and conditions
        </Checkbox>
      </ModalBody>

      <ModalFooter>
        <Button 
          colorScheme="blue" 
          mr={3} 
          onClick={onConfirm}
          isDisabled={!isAgreedToTerms}
        >
          Send Invoice
        </Button>
        <Button variant="ghost" onClick={onClose}>Cancel</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
