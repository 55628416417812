import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  useToast,
  Heading,
  Text,
} from '@chakra-ui/react';
import { collection, addDoc, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';

const ClientSelection = ({ invoiceData, setInvoiceData, handleInputChange }) => {
  const [isAddingNewClient, setIsAddingNewClient] = useState(false);
  const [newClient, setNewClient] = useState({ name: '', email: '', address: '', phone: '' });
  const [clients, setClients] = useState([]);
  const toast = useToast();
  const { currentUser, isAuthorized } = useAuth();

  useEffect(() => {
    const fetchClients = async () => {
      if (!isAuthorized) return;
      try {
        const clientsCollection = collection(db, 'clients');
        const q = query(clientsCollection, where("userId", "==", currentUser.uid));
        const clientSnapshot = await getDocs(q);
        const clientList = clientSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setClients(clientList);
      } catch (error) {
        console.error("Error fetching clients: ", error);
        toast({
          title: 'Error',
          description: 'Failed to fetch clients. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchClients();
  }, [currentUser, isAuthorized, toast]);

  const handleClientChange = async (e) => {
    const clientId = e.target.value;
    const selectedClient = clients.find(client => client.id === clientId);
    if (selectedClient) {
      setInvoiceData({
        ...invoiceData,
        clientId: clientId,
        customerName: selectedClient.name,
        customerEmail: selectedClient.email,
        customerAddress: selectedClient.address,
        customerPhone: selectedClient.phone
      });

      // Update the client in the database if any fields are missing
      if (!selectedClient.address || !selectedClient.phone) {
        try {
          const clientRef = doc(db, 'clients', clientId);
          await updateDoc(clientRef, {
            address: selectedClient.address || '',
            phone: selectedClient.phone || ''
          });
          toast({
            title: 'Client Updated',
            description: 'Client information has been updated.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          console.error('Error updating client:', error);
          toast({
            title: 'Error',
            description: 'Failed to update client information.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  };

  const handleNewClientChange = (e) => {
    const { name, value } = e.target;
    setNewClient({ ...newClient, [name]: value });
  };

  const handleAddNewClient = async () => {
    if (!newClient.name.trim() || !newClient.email.trim()) {
      toast({
        title: 'Error',
        description: 'Client name and email are required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const newClientWithUserId = {
        ...newClient,
        userId: currentUser.uid
      };
      const docRef = await addDoc(collection(db, 'clients'), newClientWithUserId);
      const newClientWithId = { id: docRef.id, ...newClientWithUserId };
      setClients([...clients, newClientWithId]);
      setInvoiceData({
        ...invoiceData,
        clientId: docRef.id,
        customerName: newClient.name,
        customerEmail: newClient.email,
        customerAddress: newClient.address,
        customerPhone: newClient.phone
      });
      setNewClient({ name: '', email: '', address: '', phone: '' });
      setIsAddingNewClient(false);
      toast({
        title: 'Success',
        description: 'New client added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding new client:', error);
      toast({
        title: 'Error',
        description: 'Failed to add new client. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {isAuthorized ? (
        <>
          <Button onClick={() => setIsAddingNewClient(!isAddingNewClient)} colorScheme="teal" mb={4}>
            {isAddingNewClient ? 'Select Existing Client' : 'Add New Client'}
          </Button>

          {isAddingNewClient ? (
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Client Name</FormLabel>
                <Input 
                  name="name" 
                  value={newClient.name} 
                  onChange={handleNewClientChange}
                  placeholder="Enter client name"
                  isInvalid={newClient.name.trim() === ''}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Client Email</FormLabel>
                <Input 
                  name="email" 
                  type="email" 
                  value={newClient.email} 
                  onChange={handleNewClientChange}
                  isInvalid={newClient.email.trim() === ''}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Client Address</FormLabel>
                <Input name="address" value={newClient.address} onChange={handleNewClientChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Client Phone</FormLabel>
                <Input name="phone" value={newClient.phone} onChange={handleNewClientChange} />
              </FormControl>
              <Button 
                onClick={handleAddNewClient} 
                colorScheme="green"
                isDisabled={!newClient.name.trim() || !newClient.email.trim()}
              >
                Add New Client
              </Button>
            </VStack>
          ) : (
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Select Client</FormLabel>
                <Select name="clientId" value={invoiceData.clientId} onChange={handleClientChange}>
                  <option value="">Select a client</option>
                  {clients.map(client => (
                    <option key={client.id} value={client.id}>{client.name}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input name="customerName" value={invoiceData.customerName} onChange={handleInputChange} />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input name="customerEmail" value={invoiceData.customerEmail} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input name="customerAddress" value={invoiceData.customerAddress} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input name="customerPhone" value={invoiceData.customerPhone} onChange={handleInputChange} />
              </FormControl>
            </VStack>
          )}
        </>
      ) : (
        <VStack spacing={4}>
          <Text>Please sign up to manage clients and create custom invoices.</Text>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input name="customerName" value={invoiceData.customerName} onChange={handleInputChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input name="customerEmail" value={invoiceData.customerEmail} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input name="customerAddress" value={invoiceData.customerAddress} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <Input name="customerPhone" value={invoiceData.customerPhone} onChange={handleInputChange} />
          </FormControl>
        </VStack>
      )}
    </Box>
  );
};

export default ClientSelection;