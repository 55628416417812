import React from 'react';
import {
  Box,
  Text,
  List,
  ListItem,
  ListIcon,
  VStack,
  HStack,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Button,
  Tooltip,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, DownloadIcon, EmailIcon } from '@chakra-ui/icons';
import { generatePDF } from '../utils/DownloadInvoice';
import InvoicePreview from './InvoicePreview';

const InvoiceFinalReview = ({ invoiceData, isInvoiceValid }) => {
  const toast = useToast();

  const isInvoiceComplete = () => {
    return (
      invoiceData.from.name &&
      invoiceData.from.email &&
      invoiceData.customerName &&
      invoiceData.customerEmail &&
      invoiceData.invoiceDate &&
      invoiceData.dueDate &&
      invoiceData.items.length > 0
    );
  };

  const handleDownload = async () => {
    if (!isInvoiceComplete()) {
      toast({
        title: 'Incomplete Invoice',
        description: 'Please complete all required fields before downloading.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const companyName = invoiceData.from.companyName || '';
      const pdfBlob = await generatePDF(invoiceData, invoiceData.logoUrl, companyName);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `invoice_${invoiceData.invoiceNumber || 'draft'}.pdf`;
      link.click();
      URL.revokeObjectURL(pdfUrl);
      toast({
        title: 'Invoice Downloaded',
        description: 'The invoice has been downloaded successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast({
        title: 'Download Failed',
        description: 'An error occurred while generating the PDF. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <VStack spacing={6} align="stretch">
      <HStack spacing={4} align="center">
        <Spacer />
        <Tooltip label={isInvoiceComplete() ? "Download Invoice" : "Complete all fields to enable download"} aria-label="Download Invoice">
          <Button
            leftIcon={<DownloadIcon />}
            onClick={handleDownload}
            colorScheme="blue"
            size="md"
            isDisabled={!isInvoiceComplete()}
          >
            Download Invoice
          </Button>
        </Tooltip>
        <Tooltip label="You must sign in to use this feature" aria-label="Send Email">
          <Button
            leftIcon={<EmailIcon />}
            colorScheme="green"
            size="md"
            isDisabled={true}
          >
            Send Email
          </Button>
        </Tooltip>
      </HStack>

      <Alert status="info">
        <AlertIcon />
        Login/Register to use all the features. However, upon completing the checklist below, you will be able to download your invoice.
      </Alert>

      <Box>
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text size="sm">Invoice Details Checklist</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={invoiceData.from.name && invoiceData.from.email ? CheckCircleIcon : WarningIcon} color={invoiceData.from.name && invoiceData.from.email ? "green.500" : "yellow.500"} />
                  Sender Information: {invoiceData.from.name && invoiceData.from.email ? "Complete" : "Incomplete"}
                </ListItem>
                <ListItem>
                  <ListIcon as={invoiceData.customerName && invoiceData.customerEmail ? CheckCircleIcon : WarningIcon} color={invoiceData.customerName && invoiceData.customerEmail ? "green.500" : "yellow.500"} />
                  Client Information: {invoiceData.customerName && invoiceData.customerEmail ? "Complete" : "Incomplete"}
                </ListItem>
                <ListItem>
                  <ListIcon as={invoiceData.invoiceDate && invoiceData.dueDate ? CheckCircleIcon : WarningIcon} color={invoiceData.invoiceDate && invoiceData.dueDate ? "green.500" : "yellow.500"} />
                  Invoice Dates: {invoiceData.invoiceDate && invoiceData.dueDate ? "Set" : "Not Set"}
                </ListItem>
                <ListItem>
                  <ListIcon as={invoiceData.items.length > 0 ? CheckCircleIcon : WarningIcon} color={invoiceData.items.length > 0 ? "green.500" : "yellow.500"} />
                  Invoice Items: {invoiceData.items.length > 0 ? `${invoiceData.items.length} item(s) added` : "No items added"}
                </ListItem>
                <ListItem>
                  <ListIcon as={CheckCircleIcon} color="green.500" />
                  Total Amount: {invoiceData.currency} {invoiceData.totalAmount.toFixed(2)}
                </ListItem>
              </List>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Box>
        <Box borderWidth={1} borderRadius="lg" p={4} bg="white" boxShadow="md">
          <InvoicePreview invoiceData={invoiceData}/>
        </Box>
      </Box>

      <Divider />
    </VStack>
  );
};

export default InvoiceFinalReview;
