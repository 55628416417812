import React from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Flex,
  Grid,
  GridItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionBox = motion.create(Box);

const InvoicePreview = ({ invoiceData, isScrollable }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  const formatCurrency = (value) => {
    const num = Number(value);
    return isNaN(num) ? '0.00' : num.toFixed(2);
  };

  const calculateTaxAmount = () => {
    return invoiceData.subtotal * (invoiceData.taxRate / 100);
  };

  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      width="180mm"
      maxWidth="100%"
      height={isScrollable ? "80vh" : "auto"}
      margin="auto"
      boxShadow="xl"
      borderRadius="md"
      bg={bgColor}
      color={textColor}
      border="1px solid #e0e0e0"
      overflow="hidden"
      sx={{
        '@media print': {
          width: '210mm',
          height: 'auto',
          boxShadow: 'none',
          border: 'none',
        },
        '@media screen and (max-width: 180mm)': {
          width: '100%',
          margin: '0 auto',
          boxShadow: 'none',
          border: 'none',
        },
      }}
    >
      <Box
        height="100%"
        overflowY={isScrollable ? "auto" : "visible"}
        padding={{ base: 4, md: 8, lg: 12 }}
        sx={{
          '&::-webkit-scrollbar': {
            width: '8px',
            borderRadius: '8px',
            backgroundColor: `rgba(0, 0, 0, 0.05)`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.15)`,
            borderRadius: '8px',
          },
        }}
      >
        <Flex justifyContent="space-between" alignItems="center" mb={4} pl="4">
          {invoiceData.logoUrl && (
            <Image 
              src={invoiceData.logoUrl} 
              alt="Invoice Logo" 
              boxSize="48px" 
              objectFit="contain" 
            />
          )}
        </Flex>
        
        <Grid templateColumns="repeat(2, 1fr)" gap={6} p={4} borderRadius="md" mb={6}>
          <GridItem>
            <HStack mb={2}>
              <Text fontWeight="bold">Invoice Number:</Text>
              <Text>{invoiceData.invoiceNumber}</Text>
            </HStack>
            <HStack mb={2}>
              <Text fontWeight="bold">Status:</Text>
              <Badge colorScheme={invoiceData.paymentStatus && invoiceData.paymentStatus.toLowerCase() === 'paid' ? 'green' : 'red'}>
                {invoiceData.paymentStatus}
              </Badge>
            </HStack>
            <HStack>
              <Text fontWeight="bold">Currency:</Text>
              <Text>{invoiceData.currency}</Text>
            </HStack>
          </GridItem>
          <GridItem>
            <HStack mb={2}>
              <Text fontWeight="bold">Issue Date:</Text>
              <Text>{invoiceData.invoiceDate ? new Date(invoiceData.invoiceDate).toLocaleDateString() : 'N/A'}</Text>
            </HStack>
            <HStack mb={2}>
              <Text fontWeight="bold">Due Date:</Text>
              <Text>{invoiceData.dueDate ? new Date(invoiceData.dueDate).toLocaleDateString() : 'N/A'}</Text>
            </HStack>
          </GridItem>
        </Grid>

        <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={6}>
          <GridItem pl="4">
            <Text fontWeight="bold" mb={2}>From:</Text>
            <Text>{invoiceData.from.name || 'Sender Name'}</Text>
            {invoiceData.from.companyName && <Text>{invoiceData.from.companyName}</Text>}
            <Text>{invoiceData.from.address || 'Sender Address'}</Text>
            {invoiceData.from.email && <Text>{invoiceData.from.email}</Text>}
            {invoiceData.from.phone && <Text>{invoiceData.from.phone}</Text>}
          </GridItem>
          <GridItem>
            <Text fontWeight="bold" mb={2}>To:</Text>
            <Text>{invoiceData.customerName}</Text>
            <Text>{invoiceData.customerAddress || 'Customer Address'}</Text>
            {invoiceData.customerEmail && <Text>{invoiceData.customerEmail}</Text>}
            {invoiceData.customerPhone && <Text>{invoiceData.customerPhone}</Text>}
          </GridItem>
        </Grid>

        <Table variant="simple" mt={6}>
          <Thead bg="gray.200">
            <Tr>
              <Th>Item</Th>
              <Th isNumeric>Quantity</Th>
              <Th isNumeric>Price</Th>
              <Th isNumeric>Total</Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoiceData.items && invoiceData.items.map((item, index) => (
              <Tr key={index}>
                <Td>{item.itemName}</Td>
                <Td isNumeric>{item.quantity}</Td>
                <Td isNumeric>{formatCurrency(item.price)} {invoiceData.currency}</Td>
                <Td isNumeric>{formatCurrency(Number(item.quantity) * Number(item.price))} {invoiceData.currency}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <Box borderTop="1px" borderColor="gray.200" pt={4} mt={6}>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Box pl="4">
              <Text fontWeight="bold">Payment Method:</Text>
              <Text>{invoiceData.paymentMethod || 'N/A'}</Text>
            </Box>
            <VStack align="stretch" spacing={2} minW="200px">
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">Subtotal:</Text>
                <Text>{formatCurrency(invoiceData.subtotal)} {invoiceData.currency}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">Tax ({invoiceData.taxRate || 0}%):</Text>
                <Text>{formatCurrency(calculateTaxAmount())} {invoiceData.currency}</Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Text fontWeight="bold">Deductions:</Text>
                <Text>{formatCurrency(invoiceData.deductions)} {invoiceData.currency}</Text>
              </Flex>
              <Flex justifyContent="space-between" fontWeight="bold" fontSize="lg" borderTop="1px" borderColor="gray.200" pt={2}>
                <Text>Total:</Text>
                <Text>{formatCurrency(invoiceData.totalAmount)} {invoiceData.currency}</Text>
              </Flex>
            </VStack>
          </Flex>
        </Box>

        {invoiceData.additionalDetails && (
          <Box p={4} borderRadius="md" mt={6}>
            <Text fontWeight="bold" mb={2}>Additional Details:</Text>
            <Text>{invoiceData.additionalDetails}</Text>
          </Box>
        )}
      </Box>
    </MotionBox>
  );
};

export default InvoicePreview;
