import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, getDocs, query, where, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Box, VStack, Heading, Input, Button, Text, List, ListItem, useToast, HStack, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Tabs, TabList, TabPanels, Tab, TabPanel, FormControl, FormLabel, Spinner } from '@chakra-ui/react';
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [newClient, setNewClient] = useState({ name: '', email: '', phone: '' });
  const [selectedClient, setSelectedClient] = useState(null);
  const [editingClient, setEditingClient] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useAuth();

  const buttonStyle = {
    size: "sm",
    bg: "white",
    color: "black",
    borderColor: "black",
    borderWidth: "1px",
    _hover: { bg: "black", color: "white" }
  };

  useEffect(() => {
    if (currentUser) {
      fetchClients();
    }
  }, [currentUser]);

  useEffect(() => {
    const filtered = clients.filter(client =>
      client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClients(filtered);
  }, [searchTerm, clients]);

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const clientsCollection = collection(db, 'clients');
      const q = query(clientsCollection, where("userId", "==", currentUser.uid));
      const clientSnapshot = await getDocs(q);
      const clientList = clientSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClients(clientList);
      setFilteredClients(clientList);
      setError(null);
    } catch (error) {
      console.error("Error fetching clients: ", error);
      setError("Failed to fetch clients. Please try again later.");
      toast({
        title: 'Error',
        description: 'Failed to fetch clients. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewClient(prev => ({ ...prev, [name]: value }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingClient(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'clients'), {
        ...newClient,
        userId: currentUser.uid
      });
      setNewClient({ name: '', email: '', phone: '' });
      fetchClients();
      toast({
        title: 'Success',
        description: 'Client added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding client: ", error);
      toast({
        title: 'Error',
        description: 'Failed to add client. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client);
  };

  const handleEditClient = (client) => {
    setEditingClient(client);
  };

  const handleUpdateClient = async () => {
    try {
      const clientRef = doc(db, 'clients', editingClient.id);
      await updateDoc(clientRef, {
        name: editingClient.name,
        email: editingClient.email,
        phone: editingClient.phone
      });
      setEditingClient(null);
      fetchClients();
      toast({
        title: 'Success',
        description: 'Client updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating client: ", error);
      toast({
        title: 'Error',
        description: 'Failed to update client. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteDoc(doc(db, 'clients', clientId));
      fetchClients();
      setSelectedClient(null);
      onClose();
      toast({
        title: 'Success',
        description: 'Client deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting client: ", error);
      toast({
        title: 'Error',
        description: 'Failed to delete client. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClearForm = () => {
    setNewClient({ name: '', email: '', phone: '' });
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <Box>
      <VStack spacing={8} align="stretch">
        <Heading as="h2" size="lg">Client Management</Heading>
        
        {error && <Text color="red.500">{error}</Text>}

        <Tabs>
          <TabList>
            <Tab>Add Client</Tab>
            <Tab>Client List</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <form onSubmit={handleSubmit}>
                <VStack spacing={4}>
                  <FormControl isRequired>
                    <FormLabel>Client Name</FormLabel>
                    <Input
                      type="text"
                      name="name"
                      value={newClient.name}
                      onChange={handleInputChange}
                      placeholder="Enter client name"
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Client Email</FormLabel>
                    <Input
                      type="email"
                      name="email"
                      value={newClient.email}
                      onChange={handleInputChange}
                      placeholder="Enter client email"
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Client Phone</FormLabel>
                    <Input
                      type="tel"
                      name="phone"
                      value={newClient.phone}
                      onChange={handleInputChange}
                      placeholder="Enter client phone"
                    />
                  </FormControl>
                  <HStack>
                    <Button type="submit" {...buttonStyle}>Add Client</Button>
                    <Button onClick={handleClearForm} {...buttonStyle}>Clear</Button>
                  </HStack>
                </VStack>
              </form>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4} align="stretch">
                <Input
                  placeholder="Search clients..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {isLoading ? (
                  <Spinner />
                ) : (
                  <List spacing={3}>
                    {filteredClients.map(client => (
                      <ListItem key={client.id} p={4} borderWidth={1} borderRadius="md" boxShadow="sm">
                        <HStack justifyContent="space-between">
                          <VStack align="start" spacing={1}>
                            <Text fontWeight="bold">{client.name}</Text>
                            <Text fontSize="sm">{client.email}</Text>
                            <Text fontSize="sm">{client.phone}</Text>
                          </VStack>
                          <HStack>
                            <Button onClick={() => handleClientSelect(client)} {...buttonStyle}>View</Button>
                            <Button onClick={() => handleEditClient(client)} {...buttonStyle}>Edit</Button>
                            <Button onClick={onOpen} {...buttonStyle}>Delete</Button>
                          </HStack>
                        </HStack>

                        <Modal isOpen={isOpen} onClose={onClose}>
                          <ModalOverlay />
                          <ModalContent>
                            <ModalHeader>Confirm Deletion</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                              Are you sure you want to delete this client? This action cannot be undone.
                            </ModalBody>
                            <ModalFooter>
                              <Button mr={3} onClick={() => handleDeleteClient(client.id)} {...buttonStyle}>
                                Delete
                              </Button>
                              <Button variant="ghost" onClick={onClose} {...buttonStyle}>Cancel</Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </ListItem>
                    ))}
                  </List>
                )}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        {selectedClient && !editingClient && (
          <Box pl="4">
            <Heading as="h3" size="md">Selected Client: {selectedClient.name}</Heading>
            <Text>Email: {selectedClient.email}</Text>
            <Text>Phone: {selectedClient.phone}</Text>
          </Box>
        )}

        {editingClient && (
          <Box>
            <Heading as="h3" size="md">Edit Client: {editingClient.name}</Heading>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Client Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  value={editingClient.name}
                  onChange={handleEditInputChange}
                  placeholder="Enter client name"
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Client Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={editingClient.email}
                  onChange={handleEditInputChange}
                  placeholder="Enter client email"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Client Phone</FormLabel>
                <Input
                  type="tel"
                  name="phone"
                  value={editingClient.phone}
                  onChange={handleEditInputChange}
                  placeholder="Enter client phone"
                />
              </FormControl>
              <HStack>
                <Button onClick={handleUpdateClient} {...buttonStyle}>Update Client</Button>
                <Button onClick={() => setEditingClient(null)} {...buttonStyle}>Cancel</Button>
              </HStack>
            </VStack>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

export default ClientManagement;
