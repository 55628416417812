import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Heading,
  VStack,
  Input,
  Select,
  Button,
  useToast,
  Collapse,
  SimpleGrid,
  useMediaQuery,
  Tooltip,
  Skeleton,
  keyframes,
  Alert,
  AlertIcon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon, RepeatIcon } from '@chakra-ui/icons';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../firebase';
import InvoiceList from '../dashboard/InvoiceList';
import SendInvoice from '../utils/SendInvoice';
import DownloadInvoice from '../utils/DownloadInvoice';

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const AnimatedStat = ({ label, value, color }) => {
  const animation = `${pulseAnimation} 0.5s ease-in-out`;
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      p={5}
      shadow="md"
      bg="white"
      transition="all 0.3s"
      _hover={{ shadow: "lg", transform: "translateY(-5px)" }}
    >
      <Stat animation={animation}>
        <StatLabel fontSize="lg" fontWeight="medium">{label}</StatLabel>
        <StatNumber fontSize="2xl" fontWeight="bold" color={color}>{value}</StatNumber>
      </Stat>
    </Box>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState({
    totalInvoices: 0,
    totalIncome: 0,
    outstandingPayments: 0,
  });
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    client: '',
    status: '',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const toast = useToast();
  const [isMobile] = useMediaQuery("(max-width: 48em)");
  const { isOpen: isSendInvoiceOpen, onOpen: onSendInvoiceOpen, onClose: onSendInvoiceClose } = useDisclosure();
  const { isOpen: isDownloadInvoiceOpen, onOpen: onDownloadInvoiceOpen, onClose: onDownloadInvoiceClose } = useDisclosure();

  const fetchDashboardData = () => {
    setIsLoading(true);
    setError(null);

    const q = query(
      collection(db, 'invoices'),
      where('userId', '==', auth.currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let totalInvoices = 0;
      let totalIncome = 0;
      let outstandingPayments = 0;

      querySnapshot.forEach((doc) => {
        const invoice = doc.data();
        totalInvoices++;
        totalIncome += invoice.totalAmount;
        if (invoice.paymentStatus !== 'Paid') {
          outstandingPayments += invoice.totalAmount;
        }
      });

      setDashboardData({ 
        totalInvoices, 
        totalIncome, 
        outstandingPayments,
      });
      setIsLoading(false);
    }, (error) => {
      console.error("Error fetching dashboard data: ", error);
      setError("Failed to fetch dashboard data. Please try again.");
      setIsLoading(false);
    });

    return unsubscribe;
  };

  useEffect(() => {
    if (!auth.currentUser) return;

    const unsubscribe = fetchDashboardData();

    return () => unsubscribe();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const handleResetFilters = () => {
    setFilters({
      startDate: '',
      endDate: '',
      client: '',
      status: '',
    });
  };

  const handleCreateInvoice = () => {
    navigate('/create-invoice');
  };

  const toggleFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const handleRefresh = () => {
    fetchDashboardData();
    toast({
      title: 'Dashboard Refreshed',
      description: 'The dashboard data has been updated.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDownload = (invoice) => {
    setSelectedInvoice(invoice);
    onDownloadInvoiceOpen();
  };

  const handleSend = (invoice) => {
    setSelectedInvoice(invoice);
    onSendInvoiceOpen();
  };

  const handleModalClose = () => {
    setSelectedInvoice(null);
    onDownloadInvoiceClose();
  };

  const buttonStyle = {
    size: "sm",
    bg: "white",
    color: "black",
    borderColor: "black",
    borderWidth: "1px",
    _hover: { bg: "black", color: "white" }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <VStack spacing={8} align="stretch">
          <Skeleton height="40px" />
          <SimpleGrid columns={isMobile ? 1 : 3} spacing={5}>
            <Skeleton height="120px" />
            <Skeleton height="120px" />
            <Skeleton height="120px" />
          </SimpleGrid>
          <Skeleton height="200px" />
        </VStack>
      );
    }

    if (error) {
      return (
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      );
    }

    return (
      <VStack spacing={8} align="stretch">
        <SimpleGrid columns={isMobile ? 1 : 3} spacing={5}>
          <AnimatedStat label="Total Invoices" value={dashboardData.totalInvoices} color="blue.500" />
          <AnimatedStat label="Total Income" value={`$${dashboardData.totalIncome.toFixed(2)}`} color="green.500" />
          <AnimatedStat label="Outstanding Payments" value={`$${dashboardData.outstandingPayments.toFixed(2)}`} color="red.500" />
        </SimpleGrid>

        <Box>
          <Flex justifyContent="space-between" mt={4} mb={4} flexWrap="wrap">
            <Button onClick={toggleFilters} leftIcon={isFiltersVisible ? <ChevronUpIcon /> : <ChevronDownIcon />} {...buttonStyle}>
              {isFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </Flex>
          <Collapse in={isFiltersVisible} animateOpacity>
            <SimpleGrid columns={isMobile ? 1 : 4} spacing={4} mb={4}>
              <Input
                type="date"
                name="startDate"
                value={filters.startDate}
                onChange={handleFilterChange}
                placeholder="From"
              />
              <Input
                type="date"
                name="endDate"
                value={filters.endDate}
                onChange={handleFilterChange}
                placeholder="To"
              />
              <Input
                name="client"
                value={filters.client}
                onChange={handleFilterChange}
                placeholder="Client Name"
              />
              <Select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                placeholder="Status"
              >
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="Overdue">Overdue</option>
              </Select>
            </SimpleGrid>
            <Button onClick={handleResetFilters} mb={4} {...buttonStyle}>Reset Filters</Button>
          </Collapse>
          <InvoiceList 
            filters={filters} 
            onDownload={handleDownload} 
            onSend={handleSend} 
            totalInvoices={dashboardData.totalInvoices}
            onCreateInvoice={handleCreateInvoice}
          />
        </Box>
      </VStack>
    );
  };

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={8}>
        <Heading as="h1" size="lg">Dashboard</Heading>
        <Tooltip label="Refresh dashboard data">
          <Button onClick={handleRefresh} leftIcon={<RepeatIcon />} {...buttonStyle}>Refresh</Button>
        </Tooltip>
      </Flex>
      {renderContent()}
      <SendInvoice
        isOpen={isSendInvoiceOpen}
        onClose={onSendInvoiceClose}
        selectedInvoice={selectedInvoice}
      />
      <Modal isOpen={isDownloadInvoiceOpen} onClose={handleModalClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Invoice</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Would you like to download the invoice as PDF?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleModalClose} {...buttonStyle}>
              Cancel
            </Button>
            <DownloadInvoice
              selectedInvoice={selectedInvoice}
              onCompletionOpen={handleModalClose}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Dashboard;
