import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Heading,
  Text,
} from '@chakra-ui/react';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';

const SenderSelection = ({ invoiceData, setInvoiceData, handleInputChange }) => {
  const [isAddingNewSender, setIsAddingNewSender] = useState(false);
  const [newSender, setNewSender] = useState({ name: '', email: '', address: '', phone: '', companyName: '' });
  const [senders, setSenders] = useState([]);
  const toast = useToast();
  const { currentUser, isAuthorized } = useAuth();

  useEffect(() => {
    const fetchSenders = async () => {
      if (!isAuthorized) return;
      try {
        const sendersCollection = collection(db, 'senders');
        const q = query(sendersCollection, where("userId", "==", currentUser.uid));
        const senderSnapshot = await getDocs(q);
        const senderList = senderSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSenders(senderList);
      } catch (error) {
        console.error("Error fetching senders: ", error);
        toast({
          title: 'Error',
          description: 'Failed to fetch senders. Please try again later.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchSenders();
  }, [currentUser, isAuthorized, toast]);

  const handleSenderChange = (e) => {
    const senderId = e.target.value;
    const selectedSender = senders.find(sender => sender.id === senderId);
    if (selectedSender) {
      setInvoiceData({
        ...invoiceData,
        from: {
          name: selectedSender.name,
          email: selectedSender.email,
          address: selectedSender.address,
          phone: selectedSender.phone,
          companyName: selectedSender.companyName
        }
      });
    }
  };

  const handleNewSenderChange = (e) => {
    const { name, value } = e.target;
    setNewSender({ ...newSender, [name]: value });
  };

  const handleAddNewSender = async () => {
    if (!newSender.name.trim() || !newSender.email.trim()) {
      toast({
        title: 'Error',
        description: 'Sender name and email are required',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const newSenderWithUserId = {
        ...newSender,
        userId: currentUser.uid
      };
      const docRef = await addDoc(collection(db, 'senders'), newSenderWithUserId);
      const newSenderWithId = { id: docRef.id, ...newSenderWithUserId };
      setSenders([...senders, newSenderWithId]);
      setInvoiceData({
        ...invoiceData,
        from: {
          name: newSender.name,
          email: newSender.email,
          address: newSender.address,
          phone: newSender.phone,
          companyName: newSender.companyName
        }
      });
      setNewSender({ name: '', email: '', address: '', phone: '', companyName: '' });
      setIsAddingNewSender(false);
      toast({
        title: 'Success',
        description: 'New sender added successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error adding new sender:', error);
      toast({
        title: 'Error',
        description: 'Failed to add new sender. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {isAuthorized ? (
        <>
          <Button 
            onClick={() => setIsAddingNewSender(!isAddingNewSender)} 
            colorScheme="teal" 
            mb={4}
          >
            {isAddingNewSender ? 'Select Existing Sender' : 'Add New Sender'}
          </Button>

          {isAddingNewSender ? (
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Sender Name</FormLabel>
                <Input 
                  name="name" 
                  value={newSender.name} 
                  onChange={handleNewSenderChange}
                  placeholder="Enter sender name"
                  isInvalid={newSender.name.trim() === ''}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Sender Email</FormLabel>
                <Input 
                  name="email" 
                  type="email" 
                  value={newSender.email} 
                  onChange={handleNewSenderChange}
                  isInvalid={newSender.email.trim() === ''}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input name="companyName" value={newSender.companyName} onChange={handleNewSenderChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Sender Address</FormLabel>
                <Input name="address" value={newSender.address} onChange={handleNewSenderChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Sender Phone</FormLabel>
                <Input name="phone" value={newSender.phone} onChange={handleNewSenderChange} />
              </FormControl>
              <Button 
                onClick={handleAddNewSender} 
                colorScheme="green"
                isDisabled={!newSender.name.trim() || !newSender.email.trim()}
              >
                Add New Sender
              </Button>
            </VStack>
          ) : (
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Select Sender</FormLabel>
                <Select 
                  value={invoiceData.from.name ? senders.find(s => s.name === invoiceData.from.name)?.id : ''} 
                  onChange={handleSenderChange}
                >
                  <option value="">Select a sender</option>
                  {senders.map(sender => (
                    <option key={sender.id} value={sender.id}>{sender.name}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input name="from.name" value={invoiceData.from.name} onChange={handleInputChange} />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Email</FormLabel>
                <Input name="from.email" value={invoiceData.from.email} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Company Name</FormLabel>
                <Input name="from.companyName" value={invoiceData.from.companyName} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input name="from.address" value={invoiceData.from.address} onChange={handleInputChange} />
              </FormControl>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input name="from.phone" value={invoiceData.from.phone} onChange={handleInputChange} />
              </FormControl>
            </VStack>
          )}
        </>
      ) : (
        <VStack spacing={4}>
          <Text>Please sign up to manage senders and create custom invoices.</Text>
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input name="from.name" value={invoiceData.from.name} onChange={handleInputChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input name="from.email" value={invoiceData.from.email} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Company Name</FormLabel>
            <Input name="from.companyName" value={invoiceData.from.companyName} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Address</FormLabel>
            <Input name="from.address" value={invoiceData.from.address} onChange={handleInputChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Phone</FormLabel>
            <Input name="from.phone" value={invoiceData.from.phone} onChange={handleInputChange} />
          </FormControl>
        </VStack>
      )}
    </Box>
  );
};

export default SenderSelection;
