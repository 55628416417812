import React from 'react';
import { Box } from '@chakra-ui/react';
import ItemList from './ItemList';

const ItemListStep = ({ invoiceData, setInvoiceData }) => {
  return (
    <Box>
      <ItemList
        invoiceData={invoiceData}
        setInvoiceData={setInvoiceData}
      />
    </Box>
  );
};

export default ItemListStep;