import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { Box, Text, VStack, HStack, Badge, Spinner, Table, Thead, Tbody, Tr, Th, Td, Image, Flex, Alert, AlertIcon, Grid, GridItem } from '@chakra-ui/react';
import { db } from '../../firebase';

const PublicInvoiceView = () => {
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const invoiceRef = doc(db, 'invoices', id);
        const invoiceSnap = await getDoc(invoiceRef);

        if (invoiceSnap.exists()) {
          setInvoice({ id: invoiceSnap.id, ...invoiceSnap.data() });
        } else {
          console.log('No such document!');
          setError('Invoice not found');
          // In development, use mock data for testing
          if (process.env.NODE_ENV === 'development') {
            setInvoice({
              id: 'mock-invoice-id',
              invoiceNumber: 'INV-001',
              invoiceDate: new Date().toISOString(),
              dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
              customerName: 'John Doe',
              customerAddress: '123 Main St, Anytown, USA',
              customerEmail: 'john.doe@example.com',
              from: {
                name: 'Jane Smith',
                address: '456 Sender Ave, Sendertown, USA',
                email: 'jane.smith@example.com',
                phone: '(555) 123-4567',
              },
              items: [
                { itemName: 'Web Design', quantity: 1, price: 1000 },
                { itemName: 'Hosting (1 year)', quantity: 1, price: 200 },
              ],
              subtotal: 1200,
              taxRate: 10,
              taxAmount: 120,
              totalAmount: 1320,
              currency: 'USD',
              paymentStatus: 'Unpaid',
              paymentMethod: 'Bank Transfer',
              additionalDetails: 'Thank you for your business!',
              logoUrl: null, // Add this line for testing purposes
            });
            setError(null);
          }
        }
      } catch (err) {
        console.error('Error fetching invoice:', err);
        setError('Error fetching invoice. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, [id]);

  if (loading) return (
    <Box textAlign="center" mt={10}>
      <Spinner size="xl" />
      <Text mt={4}>Loading invoice...</Text>
    </Box>
  );

  if (error) return (
    <Alert status="error" mt={10}>
      <AlertIcon />
      {error}
    </Alert>
  );

  if (!invoice) return (
    <Alert status="warning" mt={10}>
      <AlertIcon />
      Invoice not found
    </Alert>
  );

  const formatCurrency = (value) => {
    const num = Number(value);
    return isNaN(num) ? '0.00' : num.toFixed(2);
  };

  return (
    <Box
      width="210mm"
      height="297mm"
      margin="auto"
      padding={12}
      boxShadow="xl"
      borderRadius="md"
      bg="white"
      border="1px solid #e0e0e0"
      overflowY="auto"
      sx={{
        '@media print': {
          boxShadow: 'none',
          border: 'none',
        },
        '@media screen and (max-width: 210mm)': {
          width: '100%',
          height: 'auto',
          margin: '0',
          padding: '4',
          boxShadow: 'none',
          border: 'none',
        },
      }}
    >
      <Flex justifyContent="space-between" alignItems="center" mb={4} pl="4">
        {invoice.logoUrl && (
          <Image 
            src={invoice.logoUrl} 
            alt="Invoice Logo" 
            boxSize="48px" 
            objectFit="contain" 
          />
        )}
      </Flex>
      
      <Grid templateColumns="repeat(2, 1fr)" gap={6} p={4} borderRadius="md" mb={6}>
        <GridItem>
          <HStack mb={2}>
            <Text fontWeight="bold">Invoice Number:</Text>
            <Text>{invoice.invoiceNumber}</Text>
          </HStack>
          <HStack mb={2}>
            <Text fontWeight="bold">Status:</Text>
            <Badge colorScheme={invoice.paymentStatus && invoice.paymentStatus.toLowerCase() === 'paid' ? 'green' : 'red'}>
              {invoice.paymentStatus}
            </Badge>
          </HStack>
          <HStack>
            <Text fontWeight="bold">Currency:</Text>
            <Text>{invoice.currency}</Text>
          </HStack>
        </GridItem>
        <GridItem>
          <HStack mb={2}>
            <Text fontWeight="bold">Issue Date:</Text>
            <Text>{invoice.invoiceDate ? new Date(invoice.invoiceDate).toLocaleDateString() : 'N/A'}</Text>
          </HStack>
          <HStack mb={2}>
            <Text fontWeight="bold">Due Date:</Text>
            <Text>{invoice.dueDate ? new Date(invoice.dueDate).toLocaleDateString() : 'N/A'}</Text>
          </HStack>
        </GridItem>
      </Grid>

      <Grid templateColumns="repeat(2, 1fr)" gap={6} mb={6}>
        <GridItem pl="4">
          <Text fontWeight="bold" mb={2}>From:</Text>
          <Text>{invoice.from.name || 'Sender Name'}</Text>
          <Text>{invoice.from.address || 'Sender Address'}</Text>
          {invoice.from.email && <Text>{invoice.from.email}</Text>}
          {invoice.from.phone && <Text>{invoice.from.phone}</Text>}
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" mb={2}>To:</Text>
          <Text>{invoice.customerName}</Text>
          <Text>{invoice.customerAddress || 'Customer Address'}</Text>
          {invoice.customerEmail && <Text>{invoice.customerEmail}</Text>}
          {invoice.customerPhone && <Text>{invoice.customerPhone}</Text>}
        </GridItem>
      </Grid>

      <Table variant="simple" mt={6}>
        <Thead bg="gray.200">
          <Tr>
            <Th>Item</Th>
            <Th isNumeric>Quantity</Th>
            <Th isNumeric>Price</Th>
            <Th isNumeric>Total</Th>
          </Tr>
        </Thead>
        <Tbody>
          {invoice.items && invoice.items.map((item, index) => (
            <Tr key={index}>
              <Td>{item.itemName}</Td>
              <Td isNumeric>{item.quantity}</Td>
              <Td isNumeric>{formatCurrency(item.price)} {invoice.currency}</Td>
              <Td isNumeric>{formatCurrency(Number(item.quantity) * Number(item.price))} {invoice.currency}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Box borderTop="1px" borderColor="gray.200" pt={4} mt={6}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Box pl="4">
            <Text fontWeight="bold">Payment Method:</Text>
            <Text>{invoice.paymentMethod || 'N/A'}</Text>
          </Box>
          <VStack align="stretch" spacing={2} minW="200px">
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Subtotal:</Text>
              <Text>{formatCurrency(invoice.subtotal)} {invoice.currency}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Tax ({invoice.taxRate || 0}%):</Text>
              <Text>{formatCurrency(invoice.taxAmount)} {invoice.currency}</Text>
            </Flex>
            <Flex justifyContent="space-between" fontWeight="bold" fontSize="lg" borderTop="1px" borderColor="gray.200" pt={2}>
              <Text>Total:</Text>
              <Text>{formatCurrency(invoice.totalAmount)} {invoice.currency}</Text>
            </Flex>
          </VStack>
        </Flex>
      </Box>

      {invoice.additionalDetails && (
        <Box p={4} borderRadius="md" mt={6}>
          <Text fontWeight="bold" mb={2}>Additional Details:</Text>
          <Text>{invoice.additionalDetails}</Text>
        </Box>
      )}
    </Box>
  );
};

export default PublicInvoiceView;