import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

export default function ProtectedRoute({ children }) {
  const { currentUser } = useAuth();

  console.log('ProtectedRoute: Current user:', currentUser);

  if (!currentUser) {
    console.log('ProtectedRoute: No user authenticated, redirecting to login');
    return <Navigate to="/login" />;
  }

  console.log('ProtectedRoute: User authenticated, rendering protected content');
  return children;
}