import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
  useBreakpointValue,
} from '@chakra-ui/react';

const steps = [
  { title: 'Sender', description: 'Your details', path: 'sender-details' },
  { title: 'Client', description: 'Client information', path: 'client-selection' },
  { title: 'Invoice', description: 'Invoice details', path: 'invoice-form' },
  { title: 'Items', description: 'Add items', path: 'item-list' },
  { title: 'Review', description: 'Finalize invoice', path: 'final-review' },
];

const InvoiceSteps = () => {
  const location = useLocation();
  const orientation = useBreakpointValue({ base: 'vertical', md: 'horizontal' });
  const stepperWidth = useBreakpointValue({ base: '100%', md: 'auto' });
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const spacing = useBreakpointValue({ base: '2', md: '4' });

  const activeStep = steps.findIndex(step => location.pathname.includes(step.path));

  return (
    <Box display={{ base: 'none', md: 'block' }}>
      <Stepper 
        index={activeStep} 
        mb={8} 
        orientation={orientation}
        width={stepperWidth}
        fontSize={fontSize}
        spacing={spacing}
      >
        {steps.map((step, index) => (
          <Step key={index} as={Link} to={step.path}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box flexShrink='0'>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default InvoiceSteps;