import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Text, VStack, Container, Spinner } from '@chakra-ui/react';

const BlogPost = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadBlog = () => {
      try {
        const context = require.context('./blogList', false, /\.js$/);
        const blogModules = context.keys().map(key => context(key).default);
        const foundBlog = blogModules.find(blog => blog.id === id);
        setBlog(foundBlog || null);
      } catch (error) {
        console.error('Failed to load blog post:', error);
        setBlog(null);
      } finally {
        setLoading(false);
      }
    };

    loadBlog();
  }, [id]);

  if (loading) {
    return (
      <Container maxW="container.md" py={10}>
        <Spinner size="xl" />
      </Container>
    );
  }

  if (!blog) {
    return (
      <Container maxW="container.md" py={10}>
        <Heading>Blog post not found</Heading>
      </Container>
    );
  }

  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={6} align="stretch">
        <Heading as="h1" size="2xl">{blog.title}</Heading>
        <Box>
          <Text fontWeight="bold" display="inline">Date: </Text>
          <Text display="inline">{blog.date}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold" display="inline">Author: </Text>
          <Text display="inline">{blog.author}</Text>
        </Box>
        <Box className="blog-content" dangerouslySetInnerHTML={{ __html: blog.content }} />
      </VStack>
    </Container>
  );
};

export default BlogPost;
