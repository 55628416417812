import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider, Box, Container } from '@chakra-ui/react';
import { AuthProvider } from './contexts/AuthContext';
import Navigation from './components/navigation/Navigation';
import AppRoutes from './components/navigation/AppRoutes';
import updateSendersSchema from './utils/updateSendersSchema';

function App() {
  useEffect(() => {
    updateSendersSchema();
  }, []);

  return (
    <ChakraProvider>
      <AuthProvider>
        <Router>
          <Box minHeight="100vh" display="flex" flexDirection="column">
            <Navigation />
            <Container maxW="container.xl" flex={1} py={8}>
              <AppRoutes />
            </Container>
          </Box>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
