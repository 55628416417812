import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import {
  Box,
  useToast,
  useDisclosure,
  Button,
  ButtonGroup,
  Icon,
  Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import InvoiceProgress from './InvoiceProgress';
import InvoiceSteps from './InvoiceSteps';
import SenderDetailsStep from './SenderDetailsStep';
import ClientSelectionStep from './ClientSelection';
import InvoiceFormStep from './InvoiceFormStep';
import ItemListStep from './ItemListStep';
import FinalReviewStep from './FinalReviewStep';
import { UnsavedChangesAlert, ConfirmDraftModal, SendInvoiceModal } from './InvoiceAlerts';

const steps = [
  'sender-details',
  'client-selection',
  'invoice-form',
  'item-list',
  'final-review',
];

const InvoiceCreation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { isOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  // Mock authentication state (replace with actual auth logic in a real app)
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const initialInvoiceData = {
    invoiceNumber: '',
    customerName: '',
    customerEmail: '',
    customerAddress: '',
    items: [],
    currency: 'USD',
    subtotal: 0,
    taxRate: 0,
    deductions: 0,
    totalAmount: 0,
    invoiceDate: '',
    dueDate: '',
    paymentStatus: 'Unpaid',
    paymentMethod: '',
    additionalDetails: '',
    from: {
      name: '',
      email: '',
      address: '',
      phone: '',
      companyName: ''
    },
    logoUrl: ''
  };

  const [invoiceData, setInvoiceData] = useState(initialInvoiceData);
  const [progress, setProgress] = useState(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);

  useBeforeUnload(hasUnsavedChanges, 'You have unsaved changes. Are you sure you want to leave?');

  useEffect(() => {
    const calculateProgress = () => {
      const fields = [
        invoiceData.from.name,
        invoiceData.from.email,
        invoiceData.customerName,
        invoiceData.customerEmail,
        invoiceData.invoiceDate,
        invoiceData.dueDate,
        invoiceData.items.length > 0,
        invoiceData.logoUrl
      ];
      const filledFields = fields.filter(Boolean).length;
      return (filledFields / fields.length) * 100;
    };
    setProgress(calculateProgress());
    setHasUnsavedChanges(true);
  }, [invoiceData]);

  const calculateTotalAmount = (data) => {
    const subtotal = data.items.reduce((sum, item) => sum + (Number(item.quantity) * Number(item.price)), 0);
    const taxAmount = subtotal * (Number(data.taxRate) / 100);
    const totalAmount = subtotal + taxAmount - Number(data.deductions);
    return { subtotal, totalAmount };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedInvoiceData;
    if (name.startsWith('from.')) {
      const fromField = name.split('.')[1];
      updatedInvoiceData = {
        ...invoiceData,
        from: {
          ...invoiceData.from,
          [fromField]: value
        }
      };
    } else {
      updatedInvoiceData = { ...invoiceData, [name]: value };
    }

    if (['taxRate', 'deductions'].includes(name) || name.startsWith('items.')) {
      const { subtotal, totalAmount } = calculateTotalAmount(updatedInvoiceData);
      updatedInvoiceData.subtotal = subtotal;
      updatedInvoiceData.totalAmount = totalAmount;
    }

    setInvoiceData(updatedInvoiceData);
    setHasUnsavedChanges(true);
  };

  const handleLogoSelect = (url) => {
    if (isLoggedIn) {
      setInvoiceData(prevData => ({
        ...prevData,
        logoUrl: url
      }));
      setHasUnsavedChanges(true);
    } else {
      toast({
        title: 'Login required',
        description: 'Please log in to upload a logo.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (action) => {
    try {
      const { subtotal, totalAmount } = calculateTotalAmount(invoiceData);
      const invoiceToSave = {
        ...invoiceData,
        subtotal,
        totalAmount,
        status: action === 'send' ? 'Sent' : 'Draft',
      };

      setHasUnsavedChanges(false);
      toast({
        title: 'Invoice Created',
        description: 'Your invoice has been created successfully. You can now download it as a PDF.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Stay on the final review page so the user can download the invoice
      navigate('final-review', { replace: true });
    } catch (error) {
      console.error('Error processing invoice:', error);
      toast({
        title: 'Error',
        description: 'There was an error processing the invoice. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isInvoiceValid = true; // Always consider the invoice valid for the free version

  const currentStepIndex = steps.findIndex(step => location.pathname.endsWith(step));

  const goToNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      const nextStep = steps[currentStepIndex + 1];
      navigate(nextStep, { replace: true });
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      const previousStep = steps[currentStepIndex - 1];
      navigate(previousStep, { replace: true });
    }
  };

  const buttonStyle = {
    bg: 'blue.500',
    color: 'white',
    size: 'md',
    _hover: { bg: 'blue.600' },
    _disabled: { bg: 'gray.300', cursor: 'not-allowed' }
  };

  return (
    <Box p={4}>
      <Text fontSize="lg" fontWeight="bold" mb={4}>Free Invoice Creation</Text>
      <Text fontSize="sm" color="gray.600" mb={4}>
        Create and download your invoice for free. Log in to access additional features like logo upload and direct email.
      </Text>
      <InvoiceProgress progress={progress} />
      <InvoiceSteps />
      <Routes>
        <Route path="sender-details" element={
          <SenderDetailsStep
            invoiceData={invoiceData}
            handleInputChange={handleInputChange}
            handleLogoSelect={handleLogoSelect}
            isLoggedIn={isLoggedIn}
          />
        } />
        <Route path="client-selection" element={
          <ClientSelectionStep
            invoiceData={invoiceData}
            handleInputChange={handleInputChange}
          />
        } />
        <Route path="invoice-form" element={
          <InvoiceFormStep
            invoiceData={invoiceData}
            handleInputChange={handleInputChange}
          />
        } />
        <Route path="item-list" element={
          <ItemListStep
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
          />
        } />
        <Route path="final-review" element={
          <FinalReviewStep
            invoiceData={invoiceData}
            isInvoiceValid={isInvoiceValid}
            onSaveDraft={() => handleSubmit('draft')}
            onSendInvoice={() => handleSubmit('send')}
          />
        } />
        <Route path="*" element={<Navigate to="sender-details" replace />} />
      </Routes>

      <ButtonGroup mt={6} width="100%" justifyContent="space-between">
        <Button 
          onClick={goToPreviousStep}
          leftIcon={<Icon as={ChevronLeftIcon} />}
          {...buttonStyle}
          isDisabled={currentStepIndex === 0}
        >
          Previous
        </Button>
        <Button 
          onClick={goToNextStep}
          rightIcon={<Icon as={ChevronRightIcon} />}
          {...buttonStyle}
          isDisabled={currentStepIndex === steps.length - 1}
        >
          Next
        </Button>
      </ButtonGroup>

      <UnsavedChangesAlert
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={() => {
          setHasUnsavedChanges(false);
          navigate('/');
        }}
        cancelRef={cancelRef}
      />

      <ConfirmDraftModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          handleSubmit('draft');
          setIsConfirmModalOpen(false);
        }}
      />

      <SendInvoiceModal
        isOpen={isSendModalOpen}
        onClose={() => setIsSendModalOpen(false)}
        onConfirm={() => {
          handleSubmit('send');
          setIsSendModalOpen(false);
        }}
        isAgreedToTerms={isAgreedToTerms}
        setIsAgreedToTerms={setIsAgreedToTerms}
      />
    </Box>
  );
};

export default InvoiceCreation;
