import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import {
  Box,
  useToast,
  useDisclosure,
  Button,
  ButtonGroup,
  Icon,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, CheckIcon } from '@chakra-ui/icons';
import { collection, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import debounce from 'lodash/debounce';

import InvoiceProgress from './InvoiceProgress';
import InvoiceSteps from './InvoiceSteps';
import SenderDetailsStep from './SenderDetailsStep';
import ClientSelectionStep from './ClientSelectionStep';
import InvoiceFormStep from './InvoiceFormStep';
import ItemListStep from './ItemListStep';
import FinalReviewStep from './FinalReviewStep';
import { UnsavedChangesAlert, ConfirmDraftModal, SendInvoiceModal } from '../dashboard/InvoiceAlerts';

const steps = [
  'sender-details',
  'client-selection',
  'invoice-form',
  'item-list',
  'final-review',
];

const InvoiceCreation = () => {
  const { currentUser } = useAuth();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const { isOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const initialInvoiceData = {
    invoiceNumber: '',
    clientId: '',
    customerName: '',
    customerEmail: '',
    customerAddress: '',
    items: [],
    currency: 'USD',
    subtotal: 0,
    taxRate: 0,
    deductions: 0,
    totalAmount: 0,
    invoiceDate: '',
    dueDate: '',
    paymentStatus: 'Unpaid',
    paymentMethod: '',
    additionalDetails: '',
    from: {
      name: '',
      email: '',
      address: '',
      phone: ''
    },
    logoUrl: ''
  };

  const [invoiceData, setInvoiceData] = useState(initialInvoiceData);
  const [progress, setProgress] = useState(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isAgreedToTerms, setIsAgreedToTerms] = useState(false);

  useBeforeUnload(hasUnsavedChanges, 'You have unsaved changes. Are you sure you want to leave?');

  const autoSave = useCallback(
    debounce(async (data) => {
      if (!currentUser || !invoiceId) return;

      try {
        const invoiceRef = doc(db, 'invoices', invoiceId);
        await updateDoc(invoiceRef, {
          ...data,
          updatedAt: new Date()
        });
        setHasUnsavedChanges(false);
      } catch (error) {
        console.error('Error auto-saving invoice:', error);
      }
    }, 2000),
    [currentUser, invoiceId, toast]
  );

  useEffect(() => {
    const fetchInvoice = async () => {
      if (invoiceId) {
        try {
          const invoiceDoc = await getDoc(doc(db, 'invoices', invoiceId));
          if (invoiceDoc.exists()) {
            setInvoiceData(invoiceDoc.data());
          } else {
            navigate('/dashboard');
          }
        } catch (error) {
          console.error('Error fetching invoice:', error);
        }
      }
    };

    fetchInvoice();
  }, [invoiceId, navigate, toast]);

  useEffect(() => {
    const calculateProgress = () => {
      const fields = [
        invoiceData.from.name,
        invoiceData.from.email,
        invoiceData.customerName,
        invoiceData.customerEmail,
        invoiceData.invoiceDate,
        invoiceData.dueDate,
        invoiceData.items.length > 0,
        invoiceData.logoUrl
      ];
      const filledFields = fields.filter(Boolean).length;
      return (filledFields / fields.length) * 100;
    };
    setProgress(calculateProgress());

    if (invoiceId) {
      setHasUnsavedChanges(true);
      autoSave(invoiceData);
    }
  }, [invoiceData, invoiceId, autoSave]);

  const calculateTotalAmount = (data) => {
    const subtotal = data.items.reduce((sum, item) => sum + (Number(item.quantity) * Number(item.price)), 0);
    const taxAmount = subtotal * (Number(data.taxRate) / 100);
    const totalAmount = subtotal + taxAmount - Number(data.deductions);
    return { subtotal, totalAmount };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedInvoiceData;
    if (name.startsWith('from.')) {
      const fromField = name.split('.')[1];
      updatedInvoiceData = {
        ...invoiceData,
        from: {
          ...invoiceData.from,
          [fromField]: value
        }
      };
    } else {
      updatedInvoiceData = { ...invoiceData, [name]: value };
    }

    if (['taxRate', 'deductions'].includes(name) || name.startsWith('items.')) {
      const { subtotal, totalAmount } = calculateTotalAmount(updatedInvoiceData);
      updatedInvoiceData.subtotal = subtotal;
      updatedInvoiceData.totalAmount = totalAmount;
    }

    setInvoiceData(updatedInvoiceData);
    setHasUnsavedChanges(true);
  };

  const handleLogoSelect = (url) => {
    setInvoiceData(prevData => ({
      ...prevData,
      logoUrl: url
    }));
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (action) => {
    if (!currentUser) {
      console.error('No current user found');
      return;
    }

    try {
      const { subtotal, totalAmount } = calculateTotalAmount(invoiceData);
      const invoiceToSave = {
        ...invoiceData,
        subtotal,
        totalAmount,
        userId: currentUser.uid,
        status: action === 'send' ? 'Sent' : 'Draft',
      };

      let savedInvoiceId;
      if (invoiceId) {
        const invoiceRef = doc(db, 'invoices', invoiceId);
        await updateDoc(invoiceRef, {
          ...invoiceToSave,
          updatedAt: new Date()
        });
        savedInvoiceId = invoiceId;
      } else {
        const docRef = await addDoc(collection(db, 'invoices'), {
          ...invoiceToSave,
          createdAt: new Date(),
          updatedAt: new Date()
        });
        savedInvoiceId = docRef.id;
      }

      setHasUnsavedChanges(false);
      toast({
        title: action === 'save' ? 'Invoice Saved' : (action === 'send' ? 'Invoice Sent' : 'Draft Saved'),
        description: `Invoice has been ${action === 'send' ? 'sent' : 'saved'} successfully.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      if (action === 'save') {
        // If it's a save action, stay on the current page
        // Update the URL if it's a new invoice
        if (!invoiceId) {
          navigate(`/invoice/${savedInvoiceId}`, { replace: true });
        }
      } else {
        // For draft and send actions, navigate to dashboard
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error saving invoice:', error);
      toast({
        title: 'Error',
        description: 'There was an error saving the invoice. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isInvoiceValid = true; // Always consider the invoice valid

  const currentStepIndex = steps.findIndex(step => location.pathname.endsWith(step));

  const goToNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      const nextStep = steps[currentStepIndex + 1];
      navigate(nextStep, { replace: true });
    }
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      const previousStep = steps[currentStepIndex - 1];
      navigate(previousStep, { replace: true });
    }
  };

  const buttonStyle = {
    bg: 'blue.500',
    color: 'white',
    size: 'md',
    _hover: { bg: 'blue.600' },
    _disabled: { bg: 'gray.300', cursor: 'not-allowed' }
  };

  return (
    <Box p={4}>
      <InvoiceProgress progress={progress} />
      <InvoiceSteps />
      <Routes>
        <Route path="sender-details" element={
          <SenderDetailsStep
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
            handleInputChange={handleInputChange}
            handleLogoSelect={handleLogoSelect}
          />
        } />
        <Route path="client-selection" element={
          <ClientSelectionStep
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
            handleInputChange={handleInputChange}
          />
        } />
        <Route path="invoice-form" element={
          <InvoiceFormStep
            invoiceData={invoiceData}
            handleInputChange={handleInputChange}
          />
        } />
        <Route path="item-list" element={
          <ItemListStep
            invoiceData={invoiceData}
            setInvoiceData={setInvoiceData}
          />
        } />
        <Route path="final-review" element={
          <FinalReviewStep
            invoiceData={invoiceData}
            isInvoiceValid={isInvoiceValid}
            onSaveDraft={() => setIsConfirmModalOpen(true)}
            onSendInvoice={() => setIsSendModalOpen(true)}
          />
        } />
        <Route path="*" element={<Navigate to="sender-details" replace />} />
      </Routes>

      {/* Updated Navigation buttons */}
      <ButtonGroup mt={6} width="100%" justifyContent="space-between">
        <Button 
          onClick={goToPreviousStep}
          leftIcon={<Icon as={ChevronLeftIcon} />}
          {...buttonStyle}
          isDisabled={currentStepIndex === 0}
        >
          Previous
        </Button>
        <Button 
          onClick={() => handleSubmit('save')} 
          leftIcon={<Icon as={CheckIcon} />}
          {...buttonStyle}
        >
          Save
        </Button>
        <Button 
          onClick={goToNextStep}
          rightIcon={<Icon as={ChevronRightIcon} />}
          {...buttonStyle}
          isDisabled={currentStepIndex === steps.length - 1}
        >
          Next
        </Button>
      </ButtonGroup>

      <UnsavedChangesAlert
        isOpen={isOpen}
        onClose={onClose}
        onDiscard={() => {
          setHasUnsavedChanges(false);
          navigate('/dashboard');
        }}
        cancelRef={cancelRef}
      />

      <ConfirmDraftModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          handleSubmit('draft');
          setIsConfirmModalOpen(false);
        }}
      />

      <SendInvoiceModal
        isOpen={isSendModalOpen}
        onClose={() => setIsSendModalOpen(false)}
        onConfirm={() => {
          handleSubmit('send');
          setIsSendModalOpen(false);
        }}
        isAgreedToTerms={isAgreedToTerms}
        setIsAgreedToTerms={setIsAgreedToTerms}
      />
    </Box>
  );
};

export default InvoiceCreation;
