import React from 'react';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import SenderSelection from '../utils/SenderSelection';
import LogoUpload from '../utils/LogoUpload';

const SenderDetailsStep = ({ invoiceData, setInvoiceData, handleInputChange, handleLogoSelect, isLoggedIn }) => {
  return (
    <Box>
      <SenderSelection
        invoiceData={invoiceData}
        setInvoiceData={setInvoiceData}
        handleInputChange={handleInputChange}
      />
      <Box mt={6}>
        <Text fontSize="lg" fontWeight="bold" mb={2}>Logo Upload</Text>
        <Text mb={4}>
          {isLoggedIn
            ? "Upload your company logo to personalize your invoice. The logo will appear at the top of your invoice."
            : "Log in to personalize your invoice with your company logo."}
        </Text>
        <Tooltip 
          label="You must log in to use this feature" 
          isDisabled={isLoggedIn}
          hasArrow
        >
          <Box>
            <LogoUpload 
              onLogoSelect={handleLogoSelect} 
              isLoggedIn={isLoggedIn} 
              isDisabled={!isLoggedIn}
            />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SenderDetailsStep;
