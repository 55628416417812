import React, { useState, useEffect } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

export const generatePDF = async (data, logoUrl, companyName) => {
  console.log('Generating PDF with logoUrl:', logoUrl);
  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  let yPos = 20;

  // Add logo only if provided
  // Function to convert the image URL to a base64 data URL
const loadImageAsBase64 = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error loading image:', error);
    return null;
  }
};

if (logoUrl) {
  try {
    const logoDataUrl = await loadImageAsBase64(logoUrl);
    if (logoDataUrl) {
      const imgWidth = 16; // Adjust the size of the logo as needed
      const imgHeight = 16; // Maintain the aspect ratio of the logo
      const logoX = pageWidth - imgWidth - 25; // Position 25 units from the right edge
      const logoY = 25; // Position 25 units from the top edge
      doc.addImage(logoDataUrl, 'PNG', logoX, logoY, imgWidth, imgHeight);
      console.log('Logo added to PDF successfully');

      // Measure the text width
      const textWidth = doc.getTextWidth(companyName);

      // Calculate the center of the logo
      const textX = logoX + (imgWidth / 2) - (textWidth / 2);

      // Draw the company name centered beneath the logo
      doc.setFontSize(16);
      doc.text(companyName, textX, logoY + imgHeight + 6); // Adjust '6' for spacing between logo and text
    } else {
      console.log('Failed to convert logo to base64, skipping logo in PDF');

      // Center the company name on the page since the logo is not included
      const textWidth = doc.getTextWidth(companyName);
      const textX = (pageWidth - textWidth-25);
      doc.setFontSize(20);
      doc.text(companyName, textX, 28); // Position at a suitable Y-coordinate
    }
  } catch (error) {
    console.error('Error adding logo to PDF:', error);

    // Center the company name on the page since the logo is not included
    const textWidth = doc.getTextWidth(companyName);
    const textX = (pageWidth - textWidth - 25);
    doc.setFontSize(20);
    doc.text(companyName, textX, 28); // Position at a suitable Y-coordinate
  }
} else {
  console.log('No logo URL provided, centering company name');

  // Center the company name on the page since the logo is not included
  const textWidth = doc.getTextWidth(companyName);
  const textX = (pageWidth - textWidth - 30);
  doc.setFontSize(20);
  doc.text(companyName, textX, 28); // Position at a suitable Y-coordinate
}

// Adjust x positions for text to align it next to the logo
const textX = 25; // Adjust this value to control the spacing between the logo and the text
doc.setFontSize(11);
doc.text(`Invoice Number: ${data.invoiceNumber || 'N/A'}`, textX, yPos + 8);
doc.text(`Status: ${data.paymentStatus || 'N/A'}`, textX, yPos + 14);
doc.text(`Issue Date: ${formatDate(data.invoiceDate)}`, textX, yPos + 20);
doc.text(`Due Date: ${formatDate(data.dueDate)}`, textX, yPos + 26);

// Adjust yPos for the next section
yPos += 36; // Increase yPos only after adding the logo and text in the same row

  // From and To sections
  doc.setFontSize(11);
  doc.text('From:', 25, yPos);
  doc.setFontSize(11);
  doc.text(data.from.name || '', 25, yPos + 5);
  if (data.from.companyName) doc.text(data.from.companyName, 25, yPos + 10);
  doc.text(data.from.address || '', 25, yPos + (data.from.companyName ? 15 : 10));
  if (data.from.email) doc.text(data.from.email, 25, yPos + (data.from.companyName ? 20 : 15));
  if (data.from.phone) doc.text(data.from.phone, 25, yPos + (data.from.companyName ? 25 : 20));

  doc.setFontSize(11);
  doc.text('To:', pageWidth / 2 + 5, yPos);
  doc.setFontSize(11);
  doc.text(data.customerName, pageWidth / 2 + 5, yPos + 5);
  doc.text(data.customerAddress || '', pageWidth / 2 + 5, yPos + 10);
  if (data.customerEmail) doc.text(data.customerEmail, pageWidth / 2 + 5, yPos + 15);
  if (data.customerPhone) doc.text(data.customerPhone, pageWidth / 2 + 5, yPos + 20);

  yPos += 35; // Increased to accommodate potential extra line for company name

  // Items table
  doc.autoTable({
    startY: yPos,
    margin: { left: 25, right: 25 },
    head: [['Item', 'Quantity', 'Price', 'Total']],
    body: data.items.map(item => [
      item.itemName,
      item.quantity,
      `${formatCurrency(item.price)} ${data.currency}`,
      `${formatCurrency(Number(item.quantity) * Number(item.price))} ${data.currency}`
    ]),
    styles: {
      fontSize: 11,
      lineWidth: 0.1,
      lineColor: [100, 100, 100]
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
      fontStyle: 'bold',
      fontSize: 11
    },
    alternateRowStyles: { fillColor: false },
  });
  
  yPos = doc.lastAutoTable.finalY + 10;

  // Payment details and totals
  doc.setFontSize(11);
  doc.text(`Currency: ${data.currency || 'N/A'}`, 25, yPos + 10);
  doc.text(`Payment Method: ${data.paymentMethod || 'N/A'}`, 25, yPos);

  doc.text('Subtotal:', pageWidth - 70, yPos);
  doc.text(`${formatCurrency(data.subtotal)} ${data.currency}`, pageWidth - 25, yPos, { align: 'right' });
  
  doc.text(`Tax (${data.taxRate || 0}%):`, pageWidth - 70, yPos + 5);
  doc.text(`${formatCurrency(data.taxAmount)} ${data.currency}`, pageWidth - 25, yPos + 5, { align: 'right' });
  
  doc.setFontSize(11);
  doc.setDrawColor(200, 200, 200);
  doc.line(pageWidth - 70, yPos + 10, pageWidth - 25, yPos + 10);
  doc.text('Total:', pageWidth - 70, yPos + 15);
  doc.text(`${formatCurrency(data.totalAmount)} ${data.currency}`, pageWidth - 25, yPos + 15, { align: 'right' });

  // Additional details
  if (data.additionalDetails) {
    yPos += 25;
    doc.setFontSize(11);
    doc.text('Additional Details:', 25, yPos);
    doc.setFontSize(11);
    const splitText = doc.splitTextToSize(data.additionalDetails, pageWidth - 50);
    doc.text(splitText, 25, yPos + 5);
  }

  return doc.output('blob');
};

const formatDate = (dateString) => {
  return dateString ? new Date(dateString).toLocaleDateString() : 'N/A';
};

const formatCurrency = (value) => {
  const num = Number(value);
  return isNaN(num) ? '0.00' : num.toFixed(2);
};

const DownloadInvoice = ({ selectedInvoice, onCompletionOpen }) => {
  const toast = useToast();
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(() => {
    console.log('Selected Invoice:', selectedInvoice);
    console.log('Logo URL from invoice:', selectedInvoice?.logoUrl);

    const fetchLogoUrl = async () => {
      if (selectedInvoice && selectedInvoice.logoUrl) {
        try {
          const storage = getStorage();
          const logoRef = ref(storage, selectedInvoice.logoUrl);
          const url = await getDownloadURL(logoRef);
          console.log('Firebase Storage download URL:', url);
          setLogoUrl(url);
        } catch (error) {
          console.error('Error fetching logo URL:', error);
          setLogoUrl(null);
        }
      } else {
        console.log('No logo URL provided in the invoice');
        setLogoUrl(null);
      }
    };

    fetchLogoUrl();
  }, [selectedInvoice]);

  const handleDownload = async () => {
    if (!selectedInvoice.customerName || selectedInvoice.items.length === 0) {
      toast({
        title: 'Validation Error',
        description: 'Please ensure the invoice has a customer name and at least one item.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      console.log('Generating PDF');
      const companyName = selectedInvoice.from.companyName || '';
      const pdfBlob = await generatePDF(selectedInvoice, logoUrl, companyName);
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `invoice_${selectedInvoice.invoiceNumber || 'draft'}.pdf`;
      link.type = 'application/pdf';  // Set the MIME type for the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
      onCompletionOpen(); // Open the completion modal after download
      console.log('PDF generated and downloaded successfully');
      toast({
        title: 'Invoice Downloaded',
        description: 'The invoice has been downloaded successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while generating the PDF. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Button 
      onClick={handleDownload} 
      colorScheme="green" 
      width="auto" 
      px={6} 
      py={2}
    >
      Download
    </Button>
  );
};

export default DownloadInvoice;
